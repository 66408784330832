import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { apiDelete, apiGet } from "../../../utils/apis";

const FaqTable = () => {
  const [inputs, setInputs] = useState();
  const fetchData = async () => {
    const res = await apiGet("faq").catch((err) => console.log(err));

    const data = await res.data;

    return data;
  };

  const deleteItem = (id, e) => {
    apiDelete(`faq/${id}`)
      .then(() => alert("Are You Sure?"))
      .then(() => window.location.reload(true))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (!inputs) {
      fetchData().then((data) => {
        setInputs(data.allItem);
      });
    }
  });
  return (
    <div>
      <div className="col-md-12 grid-margin stretch-card ">
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">Prodcuts Data</h4>
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Question</th>
                    <th>Answer</th>
                    <th>Action</th>
                  </tr>
                </thead>
                {inputs &&
                  inputs.map((item, index) => (
                    <tbody key={index}>
                      <tr>
                        <td>{item.title}</td>
                        <td>{item.description}</td>
                        {/* {(id = item._id)} */}
                        <td className="d-flex flex-row align-items-center">
                          <i
                            className="fa fa-edit"
                            style={{
                              fontSize: 26,
                              marginRight: 10,
                              cursor: "pointer",
                              marginTop: 2,
                              color: "#0000ff",
                            }}
                          ></i>
                          <i
                            className="fa fa-trash-o"
                            style={{
                              fontSize: 26,
                              marginLeft: 10,
                              cursor: "pointer",
                              color: "#ff0000",
                            }}
                            onClick={(e) => deleteItem(item._id, e)}
                          ></i>
                        </td>
                      </tr>
                    </tbody>
                  ))}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqTable;
