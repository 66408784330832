import React from "react";
import { Link } from "react-router-dom";

const DropDownMenuNew = ({
  label,
  links,
  icon,
  isOpen,
  setOpenDropdown,
  currentDropdown,
}) => {
  const toggleDropdown = () => {
    if (isOpen) {
      setOpenDropdown(null); // Close the dropdown if it's already open
    } else {
      setOpenDropdown(currentDropdown); // Open the clicked dropdown
    }
  };

  return (
    <li>
      <button
        type="button"
        className="flex items-center w-full p-2 text-base text-gray-500 hover:text-gray-800 transition duration-75 rounded-lg group hover:bg-gray-100 bg-blue-50"
        onClick={toggleDropdown}
        aria-controls="dropdown-menu"
        aria-expanded={isOpen}
      >
        {icon}
        <span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap">
          {label}
        </span>
        <svg
          className={`w-3 h-3 transform transition-transform ${
            isOpen ? "rotate-180" : ""
          }`}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="m1 1 4 4 4-4"
          />
        </svg>
      </button>
      <ul
        id="dropdown-menu"
        className={`${
          isOpen ? "block" : "hidden"
        } py-2 space-y-2 transition rounded duration-75 px-2`}
      >
        {links.map((link, index) => (
          <li key={index}>
            <Link
              to={link.href}
              className="flex  items-center w-full p-2 ml-4 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 "
              style={{ textDecoration: "none" }}
            >
              - {link.label}
            </Link>
          </li>
        ))}
      </ul>
    </li>
  );
};

export default DropDownMenuNew;
