import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../../../assets/image/logo.png";
import { apiPost } from "../../../../utils/apis";

const Login = () => {
  const [identifier, setIdentifier] = useState(""); // To hold either email or username
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("authToken")) {
      navigate("/");
    }
  }, [navigate]);

  const loginHandler = async (e) => {
    e.preventDefault();
    try {
      const { data } = await apiPost("auth/login", {
        email: identifier.includes("@") ? identifier : undefined,
        username: identifier.includes("@") ? undefined : identifier,
        password,
      });

      localStorage.setItem("user", JSON.stringify(data?.data));
      localStorage.setItem("authToken", data.token);
      navigate("/");
      window.location.reload();
    } catch (error) {
      setError(error.response.data.error);
      setTimeout(() => {
        setError("");
      }, 5000);
    }
  };

  return (
    <section
      className=" gradient-form"
      style={{
        height: "100vh",
        backgroundColor: "#f2f2f2",
      }}
    >
      <div className="container py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-xl-10">
            <div className="card rounded-3 text-black">
              <div className="row g-0">
                <div className="col-lg-6">
                  <div className="card-body p-md-5 mx-md-4">
                    <div className="text-center">
                      <h4
                        className="mt-1 mb-5 pb-1"
                        style={{ textTransform: "uppercase" }}
                      >
                        Admin portal
                      </h4>
                    </div>
                    <form onSubmit={loginHandler}>
                      <p>Please login to your account</p>
                      <div className="form-outline mb-4">
                        <input
                          type="text"
                          id="identifier"
                          className="form-control"
                          onChange={(e) => setIdentifier(e.target.value)}
                          placeholder="Email or Username"
                          value={identifier}
                          required
                        />
                        <label className="form-label" htmlFor="identifier">
                          Email or Username
                        </label>
                      </div>
                      <div className="form-outline mb-4">
                        <input
                          type="password"
                          id="form2Example22"
                          onChange={(e) => setPassword(e.target.value)}
                          className="form-control"
                          placeholder="Password"
                          value={password}
                          required
                        />
                        <label className="form-label" htmlFor="form2Example22">
                          Password
                        </label>
                      </div>
                      {error && (
                        <div className="alert alert-danger" role="alert">
                          {error}
                        </div>
                      )}
                      <div className="text-center pt-1 mb-5 pb-1">
                        <button
                          className="btn btn-primary btn-block fa-lg gradient-custom-2 mb-3"
                          type="submit"
                        >
                          Log in
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="flex items-center justify-center w-full col-lg-6 bg-blue-50">
                  <img src={logo} alt="logo" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
