import React from "react";
import Sidebar from "../../Nav/Sidebar";
import PipeOrderTable from "./PipeOrderTable";
import withSidebar from "../../../utils/wrapper";

const Pipe = () => {
  return (
    <div className="container-fluid page-body-wrapper">
      <div className="row row-offcanvas row-offcanvas-right">
        <PipeOrderTable />
      </div>
    </div>
  );
};

export default withSidebar(Pipe);
