import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../Nav/Sidebar";
import { apiGet, apiUpdate } from "../../utils/apis";
import withSidebar from "../../utils/wrapper";

const EidtCareer = () => {
  const id = window.location.href.split("/")[4];
  const [Item, setItem] = useState();
  const [Inputs, setInputs] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    setInputs((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const fetchData = async () => {
    const res = await apiGet(`career/list/${id}`).catch((err) =>
      console.log(err)
    );

    const data = await res.data;
    console.log(data);
    return data;
  };

  useEffect(() => {
    fetchData().then((data) => {
      setItem(data.item);
      setInputs({
        title: data.item.title,
        jobRequirment: data.item.jobRequirment,
        jobDescription: data.item.jobDescription,
        skills: data.item.skills,
        vacancyNo: data.item.vacancyNo,
        validDate: data.item.validDate,
      });
    });
  }, [id]);

  const EditRequest = async () => {
    const res = await apiUpdate(`career/update/${id}`, {
      title: Inputs.title,
      jobRequirment: Inputs.jobRequirment,
      jobDescription: Inputs.jobDescription,
      skills: Inputs.skills,
      vacancyNo: Inputs.vacancyNo,
      validDate: Inputs.validDate,
    }).catch((err) => console.log(err));

    const data = res.data;

    return data;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    EditRequest()
      .then((data) => {
        console.log(data);
      })
      .then(() => navigate("/career-list"));
  };
  console.log(Inputs);
  return (
    <div className="container-fluid page-body-wrapper">
      <div className="row row-offcanvas row-offcanvas-right">
        <div class="content-wrapper w-full p-12 " style={{ background: "#DAEAF1" }}>
          <section className="d-flex justify-content-center">
            <div className="col-lg-12 col-xl-11">
              <h2 className="text-center fw-bold mb-5 mx-1 mx-md-4 mt-4">
                EDIT CAREER
              </h2>
              {Inputs && (
                <div className="card text-black" style={{ borderRadius: 25 }}>
                  <div className="card-body p-md-5">
                    <div className="row justify-content-center">
                      <div className="col-md-10 col-lg-12 col-xl-10 ">
                        <form className="mx-1 mx-md-4" onSubmit={handleSubmit}>
                          <div className="d-flex flex-row align-items-center mb-4">
                            <div className=" border border-light flex-fill mb-0">
                              <label
                                className="form-label"
                                htmlFor="form3Example1c"
                              >
                                Job Title
                              </label>
                              <input
                                type="text"
                                name="title"
                                value={Inputs.title}
                                onChange={handleChange}
                                id="form3Example1c"
                                className="form-control"
                              />
                            </div>
                          </div>
                          <div className="d-flex flex-row align-items-center mb-4">
                            <div className="form-outline flex-fill mb-0">
                              <label
                                className="form-label"
                                htmlFor="form3Example3c"
                              >
                                Job Description
                              </label>
                              <textarea
                                type="text"
                                name="jobDescription"
                                value={Inputs.jobDescription}
                                onChange={handleChange}
                                className="form-control"
                                style={{ width: "100%", height: 150 }}
                              />
                            </div>
                          </div>
                          <div className="d-flex flex-row align-items-center mb-4">
                            <div className="form-outline flex-fill mb-0">
                              <label
                                className="form-label"
                                htmlFor="form3Example4c"
                              >
                                Requirement
                              </label>
                              <textarea
                                type="text"
                                name="jobRequirment"
                                value={Inputs.jobRequirment}
                                onChange={handleChange}
                                className="form-control"
                                style={{ width: "100%", height: 150 }}
                              />
                            </div>
                          </div>
                          <div className="d-flex flex-row align-items-center mb-4">
                            <div className="form-outline flex-fill mb-0">
                              <label
                                className="form-label"
                                htmlFor="form3Example4cd"
                              >
                                Required Skills & Qualifications
                              </label>
                              <textarea
                                type="text"
                                name="skills"
                                value={Inputs.skills}
                                onChange={handleChange}
                                className="form-control"
                                style={{ width: "100%", height: 150 }}
                              />
                            </div>
                          </div>

                          <div className=" border border-light flex-fill mb-0">
                            <label className="form-label">Job Vacancy</label>
                            <input
                              type="text"
                              name="vacancyNo"
                              value={Inputs.vacancyNo}
                              onChange={handleChange}
                              id="form3Example1c"
                              className="form-control"
                            />
                          </div>
                          <div className=" border border-light flex-fill mb-0 input-with-post-icon datepicker">
                            <label className="form-label">
                              Last Date For Apply
                            </label>
                            <input
                              type="date"
                              name="validDate"
                              value={Inputs.validDate}
                              onChange={handleChange}
                              className="form-control input-with-post-icon datepicker "
                            />
                          </div>
                          <div className="d-flex justify-content-center mx-4 mb-3 mb-lg-4"></div>
                          <button
                            type="submit"
                            className="btn btn-primary btn-lg"
                          >
                            Post
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default withSidebar(EidtCareer);
