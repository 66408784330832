import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import { apiDelete } from "../../utils/apis";
import OrderMenu from "../frontpage/OrderStatus/OrderMenu";
import { deliveryStatusValue } from "../../utils/delivery";

const OrderTable = ({
  name,
  email,
  phone,
  pType,
  type,
  id,
  delivery,
  deliveryId,
  productType,
}) => {
  const navigate = useNavigate();
  const [onMenuClick, setOnMenuClick] = useState({});

  const handleDelete = (id, e) => {
    apiDelete(`${type}/${id}`)
      .then(() => {
        window.location.reload(true);
      })
      .catch((err) => console.log(err));
  };

  const handleNavigate = (id) => {
    if (type === "pipe") {
      navigate(`/pipe-order-details/${id}`);
    } else if (type === "scrap") {
      navigate(`/scrap-order-details/${id}`);
    } else if (type === "agent") {
      navigate(`/agent-details/${id}`);
    }
  };
  return (
    <tbody>
      <tr>
        <td>{name}</td>
        <td>{email}</td>
        <td>{deliveryId}</td>
        <td>{phone}</td>
        <td>{pType}</td>
        {productType && <td>{productType}</td>}
        <td>
          <div className=" relative">
            <div
              className={`badge ${
                delivery?.deliveryStatus === "Delivered"
                  ? "badge-success"
                  : "badge-warning"
              } badge-fw curosr-pointer`}
              style={{ cursor: "pointer" }}
              onClick={() =>
                setOnMenuClick((prevState) => ({
                  ...prevState,
                  [id]: !prevState[id],
                }))
              }
            >
              {delivery?.deliveryStatus}
            </div>
            <div
              className=" position-absolute  "
              style={{ zIndex: "100" }}
              key={id}
            >
              {onMenuClick[id] && (
                <OrderMenu
                  menuList={deliveryStatusValue}
                  setOnMenuClick={setOnMenuClick}
                  onMenuClick={onMenuClick[id]}
                  orderId={delivery?._id}
                />
              )}
            </div>
          </div>{" "}
        </td>
        <td className="d-flex flex-row align-items-center">
          <i
            className="fa fa-edit"
            onClick={(e) => handleNavigate(id, e)}
            style={{
              fontSize: 26,
              marginRight: 10,
              cursor: "pointer",
              marginTop: 2,
              color: "#0000ff",
            }}
          ></i>
          <i
            className="fa fa-trash-o"
            onClick={(e) => handleDelete(id, e)}
            style={{
              fontSize: 26,
              marginLeft: 10,
              cursor: "pointer",
              color: "#ff0000",
            }}
          ></i>
        </td>
      </tr>
    </tbody>
  );
};

export default OrderTable;
