import React from "react";
import { useState } from "react";
import { apiPost } from "../../../utils/apis";
import FaqTable from "./faqTable";
import withSidebar from "../../../utils/wrapper";

const Faq = () => {
  const [inputs, setInputs] = useState({
    question: "",
    answer: "",
  });

  const refreshPage = () => {
    window.location.reload();
  };

  const handleChange = (e) => {
    setInputs((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const postFaq = async () => {
    await apiPost("faq/addFaq", {
      title: inputs.question,
      description: inputs.answer,
    }).catch((err) => console.log(err));
  };

  const submitFaq = (e) => {
    e.preventDefault();
    postFaq().then((data) => {
      console.log(data);
      setInputs({
        question: "",
        answer: "",
      });
      refreshPage();
    });
  };
  return (
    <div className="px-12 py-12">
      <h4 className="mt-4 mb-4">Add your Faq here</h4>
      <div>
        <form
          onSubmit={submitFaq}
          className="forms-sample"
          encType="multipart/form-data"
          method="post"
        >
          <div className="form-group">
            <label htmlFor="exampleInputName1">Question</label>
            <input
              type="text"
              name="question"
              value={inputs.question}
              onChange={handleChange}
              className="form-control"
              id="exampleInputName1"
              placeholder="Question"
            />
          </div>
          <div className="form-group">
            <label>Answer</label>
            <input
              type="text"
              name="answer"
              value={inputs.answer}
              onChange={handleChange}
              className="form-control"
              id="exampleInputEmail3"
              placeholder="Answer"
            />
          </div>
          <div className="input-group-append">
            <input
              //   onClick={refreshPage}
              type="submit"
              class="btn btn-success mr-2"
              value="submit"
            />
          </div>
        </form>
      </div>
      <div className=" mt-4">
        <FaqTable />
      </div>
    </div>
  );
};

export default withSidebar(Faq);
