import React from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const Sidebar = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const navigate = useNavigate();
  const handleLogout = () => {
    window.localStorage.removeItem("authToken");
    navigate("/login");
  };
  return (
    <nav className="sidebar sidebar-offcanvas " id="sidebar">
      <ul className="nav">
        <li className="nav-item nav-profile">
          <div className="nav-link">
            <div className="profile-name">
              <p className="name">Marina Michel</p>
              <p className="designation">Super Admin</p>
            </div>
          </div>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/">
            <i className="icon-rocket menu-icon" />
            <span className="menu-title">Dashboard</span>
            <span className="badge badge-success">New</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/invoice">
            <i className="icon-rocket menu-icon" />
            <span className="menu-title">Invoice</span>
            <span className="badge badge-success">New</span>
          </Link>
        </li>
        {/* <li className="nav-item">
          <Link to={"/"} className="nav-link">
            <i className="icon-shield menu-icon" />
            <span className="menu-title">Homepage Carousal</span>
          </Link>
        </li> */}
        <li className="nav-item">
          <a
            className="nav-link"
            data-toggle="collapse"
            href="#page-layouts"
            aria-expanded="false"
            aria-controls="page-layouts"
          >
            <i className="icon-check menu-icon" />
            <span className="menu-title">Pages</span>
            <span className="badge badge-danger">3</span>
          </a>
          <div className="collapse" id="page-layouts">
            <ul className="nav flex-column sub-menu">
              <li className="nav-item d-none d-lg-block">
                <Link className="nav-link" to="/consultation-post">
                  Testimonial
                </Link>
              </li>
              <li className="nav-item">
                {" "}
                <Link className="nav-link" to="/agent">
                  Agent Details
                </Link>
              </li>
              <li className="nav-item d-none d-lg-block">
                <Link className="nav-link" to="/consultation">
                  Consultation Details
                </Link>
              </li>
            </ul>
          </div>
        </li>
        <li className="nav-item d-none d-lg-block">
          <a
            className="nav-link"
            data-toggle="collapse"
            href="#sidebar-layouts"
            aria-expanded="false"
            aria-controls="sidebar-layouts"
          >
            <i className="icon-layers menu-icon" />
            <span className="menu-title">Products</span>
            <span className="badge badge-warning">2</span>
          </a>
          <div className="collapse" id="sidebar-layouts">
            <ul className="nav flex-column sub-menu">
              <li className="nav-item">
                {" "}
                <Link className="nav-link" to="/addProducts">
                  Add Prdouct
                </Link>
              </li>
              {/* <li className="nav-item">
                {" "}
                <a
                  className="nav-link"
                  href="pages/layout/sidebar-collapsed.html"
                >
                  View All Products
                </a>
              </li> */}
            </ul>
          </div>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            data-toggle="collapse"
            href="#ui-basic"
            aria-expanded="false"
            aria-controls="ui-basic"
          >
            <i className="icon-target menu-icon" />
            <span className="menu-title">Orders</span>
            <span className="badge badge-success">2</span>
          </a>
          <div className="collapse" id="ui-basic">
            <ul className="nav flex-column sub-menu">
              <li className="nav-item">
                <Link className="nav-link" to="/pipe-order">
                  Pipe
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/scrap-order">
                  Scraps
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/custom-order">
                  Add custom order
                </Link>
              </li>
            </ul>
          </div>
        </li>
        <li className="nav-item">
          {" "}
          <a
            className="nav-link"
            data-toggle="collapse"
            href="#ui-advanced"
            aria-expanded="false"
            aria-controls="ui-advanced"
          >
            <i className="icon-cup menu-icon" />
            <span className="menu-title">News</span>
            <span className="badge badge-primary">2</span>
          </a>
          <div className="collapse" id="ui-advanced">
            <ul className="nav flex-column sub-menu">
              <li className="nav-item">
                {" "}
                <Link to="/all-news" className="nav-link">
                  Previous blogs
                </Link>
              </li>
              <li className="nav-item">
                {" "}
                <Link to={"/add-news"} className="nav-link">
                  Create News
                </Link>
              </li>
            </ul>
          </div>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            data-toggle="collapse"
            href="#form-elements"
            aria-expanded="false"
            aria-controls="form-elements"
          >
            <i className="icon-flag menu-icon" />
            <span className="menu-title">Career</span>
            <span className="badge badge-danger">2</span>
          </a>
          <div className="collapse" id="form-elements">
            <ul className="nav flex-column sub-menu">
              <li className="nav-item">
                <Link className="nav-link" to="/career-list">
                  All Career Post
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/career">
                  Post Career
                </Link>
              </li>
            </ul>
          </div>
        </li>

        <li className="nav-item">
          <a
            className="nav-link"
            data-toggle="collapse"
            href="#others"
            aria-expanded="false"
            aria-controls="others"
          >
            <i className="icon-anchor menu-icon" />
            <span className="menu-title">Others</span>
            <span className="badge badge-info">3</span>
          </a>
          <div className="collapse" id="others">
            <ul className="nav flex-column sub-menu">
              <li className="nav-item">
                <Link to={"/qoute"} className="nav-link">
                  Quotes
                </Link>
              </li>
              <li className="nav-item">
                <Link to={"/newsletter"} className="nav-link">
                  Newsletter
                </Link>
              </li>
              <li className="nav-item">
                <Link to={"/faq"} className="nav-link">
                  Faq
                </Link>
              </li>
            </ul>
          </div>
        </li>
        {/* <li className="nav-item">
          <a
            className="nav-link"
            data-toggle="collapse"
            href="#editors"
            aria-expanded="false"
            aria-controls="editors"
          >
            <i className="icon-anchor menu-icon" />
            <span className="menu-title">Delivery Tracking</span>
            <span className="badge badge-info">3</span>
          </a>
          <div className="collapse" id="editors">
            <ul className="nav flex-column sub-menu">
              <li className="nav-item">
                <a className="nav-link" href="pages/forms/text_editor.html">
                  Create Delivery
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="pages/forms/code_editor.html">
                  View All
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="pages/forms/code_editor.html">
                  Others
                </a>
              </li>
            </ul>
          </div>
        </li> */}

        {/* <li className="mt-4 nav-item nav-progress">
          <h6 className="nav-progress-heading mt-4 font-weight-normal">
            Today's Sales
            <span className="nav-progress-sub-heading">50 sold</span>
          </h6>
          <div className="progress progress-sm">
            <div
              className="progress-bar bg-info"
              role="progressbar"
              style={{ width: "25%" }}
              aria-valuenow={25}
              aria-valuemin={0}
              aria-valuemax={100}
            />
          </div>
          <h6 className="nav-progress-heading mt-4 font-weight-normal">
            Customer target
            <span className="nav-progress-sub-heading">500</span>
          </h6>
          <div className="progress progress-sm">
            <div
              className="progress-bar bg-danger"
              role="progressbar"
              style={{ width: "50%" }}
              aria-valuenow={50}
              aria-valuemin={0}
              aria-valuemax={100}
            />
          </div>
        </li> */}
        <li className="mt-4 nav-item nav-progress">
          <button
            type="button"
            className="btn btn-inverse-success btn-fw"
            onClick={handleLogout}
          >
            Logout
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Sidebar;
