import React from "react";
import Sidebar from "../Nav/Sidebar";
import withSidebar from "../../utils/wrapper";

const OrderDetails = ({
  name,
  email,
  phone,
  cName,
  cAddress,
  city,
  pName,
  pType,
  thickness,
  pQuantity,
  dAddress,
  message,
  isShown,
  agent,
}) => {
  return (
    <div className="container-fluid page-body-wrapper w-full">
      <div className="row row-offcanvas row-offcanvas-right">
        <div class="content-wrapper w-full ">
          <div class="row">
            <div className="container-fluid px-1 py-5 mx-auto w-full">
              <div className="row d-flex justify-content-center">
                <div className="col-xl-7 col-lg-8 col-md-9 col-11 text-center">
                  <h3>ORDER DETAILS</h3>
                  <p className="blue-text">
                    HEY!! YOU HAVE NEW ORDER!! HAVE FUN!!
                    <br /> REMEMBER CUSTOMERS ARE ALWAYS RIGHT
                  </p>
                  <div className="card">
                    <h5 className="text-center mb-4 mt-4">CUSTOMER DETAILS</h5>
                    <form className="card-body">
                      <div className="row justify-content-between text-left">
                        <div className="form-group col-sm-6 flex-column d-flex">
                          {" "}
                          <label className="form-control-label px-3">
                            Name<span className="text-danger"> *</span>
                          </label>{" "}
                          <input
                            type="text"
                            id="fname"
                            name="fname"
                            disabled
                            value={name}
                            onblur="validate(1)"
                          />{" "}
                        </div>
                        <div className="form-group col-sm-6 flex-column d-flex">
                          {" "}
                          <label className="form-control-label px-3">
                            Email<span className="text-danger"> *</span>
                          </label>{" "}
                          <input
                            type="text"
                            id="lname"
                            name="lname"
                            value={email}
                            disabled
                            onblur="validate(2)"
                          />{" "}
                        </div>
                      </div>
                      <div className="row justify-content-between text-left">
                        <div className="form-group col-sm-6 flex-column d-flex">
                          {" "}
                          <label className="form-control-label px-3">
                            Phone
                            <span className="text-danger"> *</span>
                          </label>{" "}
                          <input
                            type="text"
                            id="phone"
                            name="phone"
                            placeholder
                            disabled
                            value={phone}
                            onblur="validate(3)"
                          />{" "}
                        </div>
                        <div className="form-group col-sm-6 flex-column d-flex">
                          {" "}
                          <label className="form-control-label px-3">
                            Company Name
                            <span className="text-danger"> *</span>
                          </label>{" "}
                          <input
                            type="text"
                            id="mob"
                            name="mob"
                            value={cName}
                            disabled
                            onblur="validate(4)"
                          />{" "}
                        </div>
                      </div>
                      {agent && (
                        <div className="row justify-content-between text-left">
                          <div className="form-group col-sm-12 flex-column d-flex">
                            {" "}
                            <label className="form-control-label px-3">
                              Company Address
                              <span className="text-danger"> *</span>
                            </label>{" "}
                            <input
                              type="text"
                              id="job"
                              name="job"
                              value={cAddress}
                              disabled
                              onblur="validate(5)"
                            />{" "}
                          </div>
                        </div>
                      )}
                      {isShown && (
                        <div className="row justify-content-between text-left">
                          <div className="form-group col-sm-12 flex-column d-flex">
                            {" "}
                            <label className="form-control-label px-3">
                              City Name<span className="text-danger"> *</span>
                            </label>{" "}
                            <input
                              type="text"
                              id="job"
                              name="cityName"
                              value={city}
                              disabled
                              onblur="validate(6)"
                            />{" "}
                          </div>
                        </div>
                      )}
                      <div className="row justify-content-between text-left">
                        {isShown && (
                          <div className="form-group col-sm-6 flex-column d-flex">
                            {" "}
                            <label className="form-control-label px-3">
                              Product Name
                              <span className="text-danger"> *</span>
                            </label>{" "}
                            <input
                              type="text"
                              id="job"
                              name="productName"
                              disabled
                              value={pName}
                              onblur="validate(5)"
                            />{" "}
                          </div>
                        )}
                        {
                          <div className="form-group col-sm-6 flex-column d-flex">
                            {" "}
                            <label className="form-control-label px-3">
                              Product Type
                              <span className="text-danger"> *</span>
                            </label>{" "}
                            <input
                              type="text"
                              id="mob"
                              name="productType"
                              disabled
                              value={pType}
                              onblur="validate(4)"
                            />{" "}
                          </div>
                        }
                      </div>
                      <div className="row justify-content-between text-left">
                        {isShown && (
                          <div className="form-group col-sm-6 flex-column d-flex">
                            {" "}
                            <label className="form-control-label px-3">
                              Thickness<span className="text-danger"> *</span>
                            </label>{" "}
                            <input
                              type="text"
                              id="job"
                              name="job"
                              disabled
                              value={thickness}
                              onblur="validate(5)"
                            />{" "}
                          </div>
                        )}
                        <div className="form-group col-sm-6 flex-column d-flex">
                          {" "}
                          <label className="form-control-label px-3">
                            Product Quantity
                            <span className="text-danger"> *</span>
                          </label>{" "}
                          <input
                            type="text"
                            id="mob"
                            disabled
                            name="mob"
                            value={pQuantity}
                            onblur="validate(4)"
                          />{" "}
                        </div>
                      </div>
                      <div className="row justify-content-between text-left">
                        <div className="form-group col-sm-12 flex-column d-flex">
                          {" "}
                          <label className="form-control-label px-3">
                            Destination Address
                            <span className="text-danger"> *</span>
                          </label>{" "}
                          <input
                            type="text"
                            id="job"
                            disabled
                            name="destination Address"
                            value={dAddress}
                            onblur="validate(5)"
                          />{" "}
                        </div>
                      </div>
                      <div className="row justify-content-between text-left">
                        <div className="form-group col-12 flex-column d-flex">
                          {" "}
                          <label className="form-control-label px-3">
                            Message
                            <span className="text-danger"> *</span>
                          </label>{" "}
                          <textarea
                            type="text"
                            id="ans"
                            name="ans"
                            value={message}
                            disabled
                            onblur="validate(6)"
                          />{" "}
                        </div>
                      </div>
                      <div className="row justify-content-end">
                        <div className="form-group col-sm-6">
                          {" "}
                          {/* <button
                              type="submit"
                              className="btn-block btn-primary"
                            >
                              Request a demo
                            </button>{" "} */}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withSidebar(OrderDetails);
