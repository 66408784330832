import React from "react";
import { useState } from "react";
import { apiPost } from "../../utils/apis";
import Sidebar from "../Nav/Sidebar";
import TestimonialTable from "./TestimonialTable";
import withSidebar from "../../utils/wrapper";
const TestimonialPost = () => {
  const [inputs, setInput] = useState({
    testimonial: "",
    name: "",
    designation: "",
    company: "",
    image: "",
  });

  const onChangeHandler = (e) => {
    setInput((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const handlePhoto = (e) => {
    setInput({
      ...inputs,
      image: e.target.files[0],
    });
  };
  const formData = new FormData();

  const addTestimonial = async () => {
    formData.append("testimonial", inputs.testimonial);
    formData.append("name", inputs.name);
    formData.append("designation", inputs.designation);
    formData.append("company", inputs.company);
    formData.append("image", inputs.image);

    const res = await apiPost("testimonial/addTestimonial", formData).catch(
      (err) => console.log(err)
    );

    const data = await res.data;
    return data;
  };
  const refreshPage = () => {
    window.location.reload();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    addTestimonial().then((data) => {
      setInput({
        testimonial: "",
        name: "",
        designation: "",
        company: "",
        image: "",
      });
    });
  };

  return (
    <div className="container-fluid page-body-wrapper">
      <div className="row row-offcanvas row-offcanvas-right">
        <div class="content-wrapper w-full">
          <div class="row">
            <div className="container-fluid px-1 py-5 mx-auto">
              <div className="row d-flex justify-content-center">
                <div className="col-xl-7 col-lg-8 col-md-9 col-11 text-center">
                  <form
                    onSubmit={handleSubmit}
                    className="forms-sample"
                    encType="multipart/form-data"
                    method="post"
                  >
                    {/* Name input */}
                    <div className="form-outline mb-4">
                      <label className="form-label" htmlFor="form4Example1">
                        Testimonial
                      </label>
                      <textarea
                        type="text"
                        id="form4Example1"
                        name="testimonial"
                        value={inputs.testimonial}
                        onChange={onChangeHandler}
                        className="form-control"
                      />
                    </div>
                    {/* Email input */}
                    <div className="form-outline mb-4">
                      <label className="form-label">Name</label>
                      <input
                        type="text"
                        id="form4Example2"
                        className="form-control"
                        name="name"
                        value={inputs.name}
                        onChange={onChangeHandler}
                      />
                    </div>
                    {/* Message input */}
                    <div className="form-outline mb-4">
                      <label className="form-label" htmlFor="form4Example3">
                        Designation
                      </label>
                      <input
                        className="form-control"
                        id="form4Example3"
                        rows={4}
                        name="designation"
                        value={inputs.designation}
                        onChange={onChangeHandler}
                      />
                    </div>
                    <div className="form-outline mb-4">
                      <label className="form-label" htmlFor="form4Example3">
                        Company
                      </label>
                      <input
                        className="form-control"
                        id="form4Example3"
                        rows={4}
                        name="company"
                        value={inputs.company}
                        onChange={onChangeHandler}
                      />
                    </div>
                    <input
                      type="file"
                      accept=".png, .jpg, .jpeg"
                      // value={inputs.image}
                      name="image"
                      onChange={handlePhoto}
                      className="form-control file-upload-info"
                      //   disabled
                      placeholder="Upload Image"
                    />
                    <button
                      type="submit"
                      className="btn btn-primary btn-block mb-4"
                      onClick={refreshPage}
                    >
                      Send
                    </button>
                  </form>

                  <TestimonialTable className="mt-5" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withSidebar(TestimonialPost);
