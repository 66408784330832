// src/components/DiscountAndRemark.js
import React from "react";
import PrimaryInput from "../../fields/primaryInput";

const DiscountAndRemark = ({ discount, setDiscount, advance, setAdvance }) => {
  return (
    <>
      <div className="mb-4 flex flex-col gap-4">
        <PrimaryInput
          label={"Discount (%)"}
          type="number"
          value={discount}
          onChange={(e) => setDiscount(parseFloat(e.target.value))}
          min={0}
          max={100}
        />

        <PrimaryInput
          label={"Advance Payment"}
          type="number"
          value={advance}
          onChange={(e) => setAdvance(parseFloat(e.target.value))}
          min={0}
        />
      </div>
    </>
  );
};

export default DiscountAndRemark;
