import React, { useEffect, useState, useRef } from "react";
import { Add, Notification } from "iconsax-react";
import { apiGet, apiPatch } from "../../utils/apis";
import { Link, useNavigate } from "react-router-dom";

const NavBar = () => {
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const [isNotificationOpen, setNotificationOpen] = useState(false);
  const [isUserMenuOpen, setUserMenuOpen] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const dropdownRef = useRef(null);
  const userMenuRef = useRef(null);
const userItem = localStorage.getItem("user");
const getUserInfo = userItem ? JSON.parse(userItem) : {};
const username = getUserInfo?.username;
const role = getUserInfo?.role;

  const fetchNotifications = async () => {
    try {
      const response = await apiGet("notification");
      if (response?.data) {
        setNotifications(response.data.notifications);
      }
    } catch (error) {
      console.error("Error fetching notifications", error);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const markAsRead = async (id) => {
    try {
      await apiPatch(`notification/${id}/read`);
      setNotifications((prevNotifications) =>
        prevNotifications.map((notification) =>
          notification._id === id
            ? { ...notification, read: true }
            : notification
        )
      );
    } catch (error) {
      console.error("Error marking notification as read", error);
    }
  };

  const handleNotificationClick = (id) => {
    markAsRead(id);
  };

  const toggleNotification = () => {
    setNotificationOpen(!isNotificationOpen);
  };

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  const toggleUserMenu = () => {
    setUserMenuOpen(!isUserMenuOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setNotificationOpen(false);
    }
    if (userMenuRef.current && !userMenuRef.current.contains(event.target)) {
      setUserMenuOpen(false);
    }
  };

  useEffect(() => {
    if (isNotificationOpen || isUserMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isNotificationOpen, isUserMenuOpen]);

  const unreadNotifications = notifications.filter((notif) => !notif.read);
  const readNotifications = notifications.filter((notif) => notif.read);

  const displayedNotifications = showAll
    ? [...unreadNotifications, ...readNotifications]
    : unreadNotifications.length > 0
    ? unreadNotifications.slice(0, 4)
    : notifications.slice(0, 5);

  const handleLogout = () => {
    window.localStorage.removeItem("authToken");
    window.localStorage.removeItem("user");
    navigate("/login");
  };

  const handleNotifactionRoute = (notifcation) => {
    if (notifcation.includes("order")) {
      navigate("/pipe-order");
    }

    if (notifcation.includes("scrap")) {
      navigate("/scrap-order");
    }
  };

  return (
    <>
      <nav className="bg-blue-50 shadow-sm py-3 px-6 flex justify-between items-center">
        {role === "admin" && (
          <Link to="/new-user">
            <div className="w-[120px] rounded-lg flex gap-2 items-center  bg-blue-500 py-2 px-2 text-center text-white font-medium">
              <p>Create user</p>
              <Add size={24} color="#fff" />
            </div>
          </Link>
        )}
        <div className="flex items-end justify-end w-full space-x-6">
          <div className="relative" ref={dropdownRef}>
            <button
              onClick={toggleNotification}
              className="relative focus:outline-none"
            >
              <Notification size={24} color="#000" />
              {unreadNotifications.length > 0 && (
                <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs rounded-full h-4 w-4 flex items-center justify-center">
                  {unreadNotifications.length}
                </span>
              )}
            </button>
            {isNotificationOpen && (
              <div className="absolute right-0 mt-2 w-64 max-h-[350px] overflow-y-auto bg-white border border-gray-200 rounded-lg shadow-lg z-50">
                {displayedNotifications.map((notification) => (
                  <div
                    key={notification._id}
                    className={`py-2 px-4 border-y cursor-pointer hover:bg-blue-50 text-[10px] font-medium ${
                      notification.read
                        ? "text-gray-500"
                        : "text-gray-700 bg-blue-50"
                    }`}
                    onClick={() => {
                      handleNotificationClick(notification._id);
                      handleNotifactionRoute(notification.message);
                    }}
                  >
                    <p>{notification.message}</p>
                    <p className="text-end">
                      <span className="text-[8px] text-gray-500">
                        {new Date(notification.date).toLocaleString()}
                      </span>
                    </p>
                  </div>
                ))}
                <div
                  className="py-2 px-4 text-blue-600 text-center cursor-pointer"
                  onClick={toggleShowAll}
                >
                  {showAll
                    ? "View less notifications"
                    : "View all notifications"}
                </div>
              </div>
            )}
          </div>
          <div className="relative" ref={userMenuRef}>
            <div
              onClick={toggleUserMenu}
              className="flex items-center space-x-2 cursor-pointer"
            >
              <div className="text-gray-800">{username}</div>
              <div className="w-8 h-8 bg-blue-300 rounded-full flex items-center justify-center text-white font-semibold">
                {username?.charAt(0).toUpperCase()}
              </div>
            </div>
            {isUserMenuOpen && (
              <div className="absolute right-0 mt-2 w-40 bg-white border border-gray-200 rounded-lg shadow-lg z-50">
                <Link to="/user-profile" style={{ textDecoration: "none" }}>
                  <div className="py-2 px-4 cursor-pointer no-underline hover:bg-blue-50">
                    Profile
                  </div>
                </Link>
                <div
                  className="py-2 px-4 cursor-pointer hover:bg-blue-50"
                  onClick={handleLogout}
                >
                  Logout
                </div>
              </div>
            )}
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavBar;
