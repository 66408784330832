import React from "react";
import { useState } from "react";
import axios from "axios";

import OrderDetails from "../OrderDetails";
import { useEffect } from "react";
import { apiGet } from "../../../utils/apis";

const ScrapOrder = () => {
  const [inputs, setInputs] = useState();
  const id = window.location.href.split("/")[4];

  const fetchData = async () => {
    const res = await apiGet(`scrap/list/${id}`).catch((err) =>
      console.log(err)
    );

    const data = await res.data;
    return data;
  };
  useEffect(() => {
    if (!inputs) {
      fetchData().then((data) => setInputs(data.list));
    }
  });
  return (
    <>
      {inputs && (
        <OrderDetails
          name={inputs.name}
          email={inputs.email}
          phone={"0" + inputs.phone}
          cName={inputs.companyName}
          cAddress={inputs.companyAddress}
          city={inputs.cityName}
          pType={inputs.scrapType}
          thickness={inputs.thickness}
          pQuantity={inputs.quantity}
          dAddress={inputs.destinationAddress}
          message={inputs.message}
          isShown={false}
        />
      )}
    </>
  );
};

export default ScrapOrder;
