import React, { useCallback, useEffect, useState } from "react";
import { apiGet } from "../../utils/apis";
import OrderStatusList from "./OrderStatus/OrderStatusList";

const Homepage = () => {
  const [customOrders, setCustomOrders] = useState([]);
  const [pipeOrders, setPipeOrders] = useState([]);
  const [scrapOrders, setScrapOrders] = useState([]);

  const fetchCustomOrders = useCallback(async () => {
    const response = await apiGet("/customOrder");
    setCustomOrders(response?.data?.data);
  }, []);

  const fetchPipeOrders = useCallback(async () => {
    const response = await apiGet("/pipe");

    setPipeOrders(response?.data?.allOrder);
  }, []);

  const fetchScrapOrders = useCallback(async () => {
    const response = await apiGet("/scrap");

    setScrapOrders(response?.data?.order);
  }, []);

  const filterCompletedOrders = (array) => {
    return array?.filter((item) => {
      return item?.delivery?.deliveryStatus === "Delivered";
    });
  };

  /*
    Delivery status is added to the orders state
    */

  useEffect(() => {
    fetchCustomOrders();
    fetchPipeOrders();
    fetchScrapOrders();
  }, [fetchCustomOrders, fetchPipeOrders, fetchScrapOrders]);
  return (
    <>
      <div className="content-wrapper w-full">
        <div className="row">
          <div className="col-md-6 col-lg-3 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="d-flex align-items-center justify-content-md-center">
                  <i className="mdi mdi-basket icon-lg text-success" />
                  <div className="ml-3">
                    <p className="mb-0">Total Order</p>
                    <h6>
                      {customOrders?.length +
                        pipeOrders?.length +
                        scrapOrders?.length}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="d-flex align-items-center justify-content-md-center">
                  <i className="mdi mdi-rocket icon-lg text-warning" />
                  <div className="ml-3">
                    <p className="mb-0">Order Completed</p>
                    <h6>
                      {filterCompletedOrders(customOrders)?.length +
                        filterCompletedOrders(pipeOrders)?.length +
                        filterCompletedOrders(scrapOrders)?.length}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="d-flex align-items-center justify-content-md-center">
                  <i className="mdi mdi-diamond icon-lg text-info" />
                  <div className="ml-3">
                    <p className="mb-0">Total Pipe Order</p>
                    <h6>{pipeOrders?.length}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 grid-margin stretch-card">
            <div className="card">
              <div className="card-body">
                <div className="d-flex align-items-center justify-content-md-center">
                  <i className="mdi mdi-chart-line-stacked icon-lg text-danger" />
                  <div className="ml-3">
                    <p className="mb-0">Total Scrup Order</p>
                    <h6>{scrapOrders?.length}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <OrderStatusList
          orders={customOrders}
          tableTitle="Custom Orders Status"
          link="/custom-order"
        />
        <OrderStatusList orders={pipeOrders} tableTitle="Pipe Orders Status" />
        <OrderStatusList
          orders={scrapOrders}
          tableTitle="Scrap Orders Status"
        />
      </div>
    </>
  );
};

export default Homepage;
