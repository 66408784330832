import React, { useState } from "react";
import withSidebar from "../../utils/wrapper";
import PrimaryInput from "../primary/PrimaryInput";
import Dropdownmenu from "../primary/Dropdownmenu";
import { apiPatch, apiPost } from "../../utils/apis";
import { toast } from "react-toastify";

const UserProfile = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const user = JSON.parse(localStorage.getItem("user"));
  const userId = user.id;

  const [password, setPassword] = useState({
    password: "",
    confirmPassword: "",
  });

  const [inputs, setInputs] = useState({
    name: user.name || "",
    username: user.username || "",
    phone: user.phone || "",
    email: user.email || "",
    role: user.role || "editor",
  });

  const handleChange = (e) => {
    setInputs((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handlePasswordChange = (e) => {
    setPassword((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleUserInfoSubmit = async (e) => {
    e.preventDefault();
    const data = {
      name: inputs.name,
      phone: inputs.phone,
      role: inputs.role,
    };
    const res = await apiPatch(`users/${userId}`, data);

    if (res?.data?.success) {
      localStorage.setItem("user", JSON.stringify(res?.data?.data));
      toast.success("User details updated successfully");
    } else {
      toast.error("Failed to update user details");
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    const passwordData = password.confirmPassword;
    const res = await apiPatch(`users/${userId}`, { password: passwordData });
    if (res?.data?.success) {
      toast.success("Password updated successfully");
      setPassword({
        password: "",
        confirmPassword: "",
      });
    } else {
      toast.error("Failed to update password");
    }
  };

  return (
    <div className="container-fluid px-1 py-5 ">
      <div className="flex justify-center items-center lg:items-start flex-col lg:flex-row gap-4 h-full">
        <div className="h-full">
          <div className="card">
            <h5 className="text-start mb-4 mt-4 uppercase">Profile DETAILS</h5>
            <form
              className="bg-blue-50 py-6 px-6 rounded-lg"
              onSubmit={handleUserInfoSubmit}
            >
              <div className="flex gap-2 justify-between">
                <PrimaryInput
                  label="Name"
                  name="name"
                  type="text"
                  value={inputs.name}
                  onChange={handleChange}
                  required
                />
                <PrimaryInput
                  label="Username"
                  name="username"
                  type="text"
                  value={inputs.username}
                  onChange={handleChange}
                  disabled
                />
              </div>
              <div className="flex gap-2 justify-between">
                <PrimaryInput
                  label="Email"
                  name="email"
                  type="email"
                  value={inputs.email}
                  onChange={handleChange}
                  disabled
                />
                <PrimaryInput
                  label="Phone"
                  name="phone"
                  type="text"
                  value={inputs.phone}
                  onChange={handleChange}
                />
              </div>

              {user.role === "admin" && (
                <div>
                  <Dropdownmenu
                    label="Role"
                    id="role"
                    name="role"
                    value={inputs.role}
                    onChange={handleChange}
                    options={["admin", "editor"]}
                  />
                </div>
              )}
              <div className="mt-4 text-end">
                <button className="btn btn-primary" type="submit">
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="h-full">
          <div className="card">
            <h5 className="text-start mb-4 mt-4 uppercase">update password</h5>
            <form
              className="bg-blue-50 py-6 px-6 rounded-lg"
              onSubmit={handlePasswordSubmit}
            >
              <div className="flex justify-between gap-2">
                <div className="w-full relative">
                  <PrimaryInput
                    label="Password"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    value={password.password}
                    onChange={handlePasswordChange}
                  />
                  <div className=" absolute top-[42%] right-2">
                    <p
                      className="text-end cursor-pointer   w-[40px] select-none"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? "Hide" : "Show"}
                    </p>
                  </div>
                </div>

                <PrimaryInput
                  label="Confirm Password"
                  name="confirmPassword"
                  type={showPassword ? "text" : "password"}
                  value={password.confirmPassword}
                  onChange={handlePasswordChange}
                />
              </div>

              {error && (
                <div className="text-red-500 text-sm mt-2 mb-4">{error}</div>
              )}

              <div className="mt-4 text-end">
                <button className="btn btn-primary" type="submit">
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withSidebar(UserProfile);
