import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import OrderTable from "../OrderTable";
import { apiGet } from "../../../utils/apis";
import { config } from "../../../utils/config";

const AgentListTable = () => {
  const [inputs, setInputs] = useState();

  const fetchData = async () => {
    const res = await apiGet("agent").catch((err) => console.log(err));
    const data = res.data;
    return data;
  };

  useEffect(() => {
    fetchData().then((data) => {
      if (!inputs) {
        setInputs(data.agentDetails);
      }
    });
  });
  console.log("All inputes", inputs);
  return (
    <div class="content-wrapper w-full">
      <div class="row">
        <div className="col-md-12 grid-margin stretch-card ">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">AGENT LIST</h4>
              <div className="table-responsive"></div>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Company Name</th>
                    <th>Action</th>
                  </tr>
                </thead>
                {inputs &&
                  inputs.map((item, index) => (
                    <OrderTable
                      name={item.name}
                      email={item.email}
                      phone={item.phone}
                      pType={item.companyName}
                      type="agent"
                      id={item._id}
                    />
                  ))}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentListTable;
