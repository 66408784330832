import React, { useState, useEffect } from "react";
import { DataTable } from "mantine-datatable";
import { TextInput, Container } from "@mantine/core";
import withSidebar from "../../utils/wrapper";
import { apiDelete, apiGet } from "../../utils/apis";
import "@mantine/core/styles.layer.css";
import "mantine-datatable/styles.layer.css";
import { IconSearch } from "@tabler/icons-react";
import { convertToDateString } from "../../utils/dates";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
const InvoiceList = () => {
  const [invoices, setInvoices] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortStatus, setSortStatus] = useState({
    columnAccessor: "invoiceId",
    direction: "asc",
  });

  useEffect(() => {
    fetchInvoices();
  }, []);

  const fetchInvoices = async () => {
    try {
      const res = await apiGet("invoice");
      const data = await res.data;
      setInvoices(data?.invoices);
    } catch (err) {
      console.log(err);
    }
  };
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const filteredInvoices = invoices.filter((invoice) =>
    Object.values(invoice)
      .join(" ")
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  const deleteInvoice = async (id) => {
    try {
      const res = await apiDelete(`invoice/${id}`);
      await res.data;
      toast.success("Invoice deleted successfully");
      fetchInvoices();
    } catch (err) {
      toast.error("Failed to delete invoice");
    }
  };

  const sortedInvoices = filteredInvoices.sort((a, b) => {
    const { columnAccessor, direction } = sortStatus;
    if (a[columnAccessor] < b[columnAccessor]) {
      return direction === "asc" ? -1 : 1;
    }
    if (a[columnAccessor] > b[columnAccessor]) {
      return direction === "asc" ? 1 : -1;
    }
    return 0;
  });

  return (
    <div className="mt-16 w-full py-4 px-6">
      <div className="flex justify-end cursor-pointer">
        <Link
          to="/create-invoice"
          className="decoration-none "
          style={{ textDecoration: "none" }}
        >
          <div className="py-2 px-4 bg-purple-500 text-white ">
            Create invoice
          </div>
        </Link>
      </div>
      <Container className="w-full">
        {sortedInvoices.length > 0 ? (
          <div>
            {" "}
            <TextInput
              placeholder="Search invoices"
              icon={<IconSearch />}
              value={searchQuery}
              onChange={handleSearchChange}
              mb="md"
              className="border-2 rounded lg:w-1/4 p-2"
            />
            <DataTable
              records={sortedInvoices}
              columns={[
                { accessor: "invoiceId", title: "Invoice ID", sortable: true },
                {
                  accessor: "customer.name",
                  title: "Customer Name",
                  sortable: true,
                },
                {
                  accessor: "customer.phone",
                  title: "Customer Phone",
                  sortable: true,
                },
                {
                  accessor: "subTotal",
                  title: "Total",
                  sortable: true,
                },
                {
                  accessor: "due",
                  title: "Due",
                  sortable: true,
                },
                { accessor: "status", title: "Status", sortable: true },
                {
                  accessor: "basicDetails.date",
                  title: "Created At",
                  sortable: true,
                  render: (record) =>
                    convertToDateString(record.basicDetails.date),
                },
                {
                  accessor: "basicDetails.dueDate",
                  title: "Due Date",
                  sortable: true,
                  render: (record) =>
                    convertToDateString(record.basicDetails.dueDate),
                },
                {
                  accessor: "",
                  title: "Action",
                  sortable: true,
                  render: (record) => {
                    return (
                      <div className="flex gap-2 items-center">
                        <Link
                          to={`/create-invoice?invoiceID=${record.invoiceId}`}
                        >
                          <i
                            className="fa fa-edit text-2xl text-blue-500 cursor-pointer"
                            aria-hidden="true"
                          ></i>
                        </Link>
                        <i
                          className="fa fa-trash text-2xl text-red-500 cursor-pointer"
                          aria-hidden="true"
                          onClick={() => deleteInvoice(record._id)}
                        ></i>
                      </div>
                    );
                  },
                },
              ]}
              sortStatus={sortStatus}
              onSortStatusChange={setSortStatus}
              pagination
              totalRecords={sortedInvoices.length}
              recordsPerPage={10}
              withBorder
              withColumnBorders
              className="w-full"
              styles={{
                header: {
                  backgroundColor: "#f5f5f5",
                  fontWeight: "bold",
                },
                row: {
                  "&:hover": {
                    backgroundColor: "#f0f0f0",
                  },
                },
                cell: {
                  padding: "20px",
                },
              }}
            />
          </div>
        ) : (
          <div className="text-center">No invoices found</div>
        )}
      </Container>
    </div>
  );
};

export default withSidebar(InvoiceList);
