import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { apiDelete, apiGet } from "../../utils/apis";
import withSidebar from "../../utils/wrapper";

const AllCareer = () => {
  const [Inputs, setInputs] = useState();

  const navigate = useNavigate();

  const fetchData = async () => {
    const res = await apiGet("career").catch((err) => console.log(err));

    const data = await res.data;
    return data;
  };

  useEffect(() => {
    if (!Inputs) {
      fetchData().then((data) => {
        setInputs(data.allItems);
      });
    }
  });

  const handleDelete = (id, e) => {
    apiDelete(`career/${id}`)
      .then(() => {
        window.location.reload(true);
      })
      .catch((err) => console.log(err));
  };

  const handleNavigate = (id) => {
    navigate(`/edit-career/${id}`);
  };

  console.log(Inputs);
  return (
    <div className="container-fluid page-body-wrapper">
      <div className="row row-offcanvas row-offcanvas-right">
        <div className="content-wrapper w-full">
          <div className="row ">
            <div className="col-md-12 grid-margin stretch-card ">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">CAREER </h4>
                  <div className="table-responsive"></div>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>TITLE</th>
                        <th>JOB DESCRIPTION</th>
                        <th>ACTION</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Inputs &&
                        Inputs.map((item) => (
                          <tr>
                            <td> {item.title} </td>
                            <td>
                              {item.jobDescription.substring(0, 30) + "......"}
                            </td>

                            <td className="d-flex flex-row align-items-center">
                              <i
                                className="fa fa-edit"
                                onClick={(e) => handleNavigate(item._id, e)}
                                style={{
                                  fontSize: 26,
                                  marginRight: 10,
                                  cursor: "pointer",
                                  marginTop: 2,
                                  color: "#0000ff",
                                }}
                              ></i>
                              <i
                                className="fa fa-trash-o"
                                onClick={(e) => handleDelete(item._id, e)}
                                style={{
                                  fontSize: 26,
                                  marginLeft: 10,
                                  cursor: "pointer",
                                  color: "#ff0000",
                                }}
                              ></i>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withSidebar(AllCareer);
