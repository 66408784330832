import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiDelete, apiGet } from "../../utils/apis";
import Sidebar from "../Nav/Sidebar";
import withSidebar from "../../utils/wrapper";

const ConsultationDetails = () => {
  const [Inputs, setInputs] = useState([]);
  const navigate = useNavigate();

  const fetchData = async () => {
    const res = await apiGet("consultation").catch((err) => console.log(err));
    const data = await res.data;
    console.log(data);
    return data;
  };
  useEffect(() => {
    fetchData().then((data) => setInputs(data.consultationItems));
  }, []);

  const handleView = (id) => {
    navigate(`/cousultation-details/${id}`);
  };

  const handleDelete = async (id) => {
    apiDelete(`consultation/${id}`)
      .then(() => window.location.reload(true))
      .catch((err) => console.log(err));
  };

  return (
    <div className="container-fluid page-body-wrapper">
      <div className="row row-offcanvas row-offcanvas-right">
        <div class="content-wrapper w-full">
          <div class="row">
            <div className="col-md-12 grid-margin stretch-card ">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Consultation Table</h4>
                  <div className="table-responsive"></div>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    {Inputs &&
                      Inputs.map((item, index) => (
                        <tbody>
                          <tr>
                            <td>{item.name}</td>
                            <td>{item.email}</td>
                            <td>{item.phone}</td>

                            <td className="d-flex flex-row align-items-center">
                              <i
                                className="fa fa-edit"
                                onClick={() => handleView(item._id)}
                                style={{
                                  fontSize: 26,
                                  marginRight: 10,
                                  cursor: "pointer",
                                  marginTop: 2,
                                  color: "#0000ff",
                                }}
                              ></i>
                              <i
                                className="fa fa-trash-o"
                                onClick={() => handleDelete(item._id)}
                                style={{
                                  fontSize: 26,
                                  marginLeft: 10,
                                  cursor: "pointer",
                                  color: "#ff0000",
                                }}
                              ></i>
                            </td>
                          </tr>
                        </tbody>
                      ))}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withSidebar(ConsultationDetails);
