import React from "react";
import { useState, useEffect } from "react";
import { apiDelete, apiGet } from "../../utils/apis";

const UserListTable = () => {
  const [users, setUsers] = useState();
  let user = localStorage.getItem("user");

  const fetchData = async () => {
    const res = await apiGet("auth/users").catch((err) => console.log(err));
    const data = res.data;
    return data;
  };

  useEffect(() => {
    fetchData().then((data) => {
      if (!users) {
        setUsers(data?.data.users);
      }
    });
  }, [users, user]);

  const handleDelete = (id) => {
    apiDelete(`auth/delete/${id}`)
      .catch((err) => console.log(err))
      .then((data) => {
        setUsers((prevState) => {
          return prevState.filter((item) => item?._id !== id);
        });
      })
      .catch((err) => console.log(err));
  };
  return (
    <div className="content-wrapper w-full ">
      <div className="row">
        <div className="col-md-12 grid-margin stretch-card ">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">USER LIST</h4>
              <div className="table-responsive"></div>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Username</th>
                    <th>Phone</th>
                    <th>Email</th>
                    <th>Role</th>
                  </tr>
                </thead>
                <tbody>
                  {users &&
                    users.map((item, index) => {
                      return (
                        <tr key={item?._id}>
                          <td>{item?.name}</td>
                          <td>{item?.username}</td>
                          <td>{item?.phone}</td>
                          <td>{item?.email}</td>
                          <td> {item.role}</td>
                          {user && user !== item?._id && (
                            <td className="d-flex flex-row align-items-center">
                              <i
                                className="fa fa-trash-o"
                                onClick={(e) => handleDelete(item?._id, e)}
                                style={{
                                  fontSize: 26,
                                  marginLeft: 10,
                                  cursor: "pointer",
                                  color: "#ff0000",
                                }}
                              ></i>
                            </td>
                          )}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserListTable;
