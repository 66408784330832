import React from "react";

const InvoiceStatus = ({ status, setStatus }) => {
  return (
    <div className="mb-4">
      <label className="block text-sm font-medium">Status</label>
      <select
        className="mt-1 block w-full py-2 px-2 rounded border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        value={status}
        onChange={(e) => setStatus(e.target.value)}
      >
        <option value="due">Due</option>
        <option value="paid">Paid</option>
      </select>
    </div>
  );
};

export default InvoiceStatus;
