import React from "react";
import { useState } from "react";
import { apiPost } from "../../../utils/apis";
import Texteditor from "../../texteditor/Texteditor";
import withSidebar from "../../../utils/wrapper";
import { toast } from "react-toastify";

const AddNewsPage = () => {
  const [inputs, setinputs] = useState({
    title: "",
    image: "",
    category: "",
    author: "",
  });

  const [description, setDescription] = useState("");
  const formData = new FormData();

  const handleChange = (e) => {
    setinputs((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const refreshPage = () => {
    window.location.reload();
  };

  const handlePhoto = (e) => {
    setinputs({
      ...inputs,
      image: e.target.files[0],
    });
  };

  const sendRequest = async () => {
    formData.append("title", inputs.title);
    formData.append("description", description);
    formData.append("category", inputs.category);
    formData.append("author", inputs.author);
    formData.append("image", inputs.image);
    await apiPost("news/add", formData).catch((err) => console.log(err));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    sendRequest().then((data) => {
      toast.success("News added successfully");
      setinputs({
        title: "",
        image: "",
      });
      setDescription("");
    });
  };

  const handleCancle = (e) => {
    e.preventDefault();
    setinputs({
      title: "",
      description: "",
      image: "",
    });
  };

  return (
    <div className="container-fluid px-12 py-5 mx-auto mh-100">
      <div className="row d-flex justify-content-center mh-100">
        <div className="col-xl-12 col-lg-8 col-md-9 col-11 mb-4">
          {inputs && (
            <form
              onSubmit={handleSubmit}
              className="forms-sample"
              encType="multipart/form-data"
              method="post"
            >
              <div className="form-group ml-4">
                <label htmlFor="exampleInputName1">News Title</label>
                <input
                  type="text"
                  name="title"
                  value={inputs.title}
                  onChange={handleChange}
                  className="form-control"
                  id="exampleInputName1"
                  placeholder="Title"
                />
              </div>
              <div className="col-12 col-md-6 d-flex">
                <div className="form-group col-6 col-md-6">
                  <label htmlFor="exampleInputName1">Category</label>
                  <input
                    type="text"
                    name="category"
                    value={inputs.category}
                    onChange={handleChange}
                    className="form-control"
                    id="exampleInputName1"
                    placeholder="Add Category"
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="exampleInputName1">Author</label>
                  <input
                    type="text"
                    name="author"
                    value={inputs.author}
                    onChange={handleChange}
                    className="form-control"
                    id="exampleInputName1"
                    placeholder="Add Author name..."
                  />
                </div>
                <div className="form-group col-6">
                  <label> Upload news cover photo</label>
                  <div className="input-group col-xs-12">
                    <input
                      type="file"
                      accept=".png, .jpg, .jpeg"
                      // value={inputs.image}
                      name="image"
                      onChange={handlePhoto}
                      className="form-control file-upload-info"
                      //   disabled
                      placeholder="Upload Image"
                    />
                  </div>
                </div>
              </div>

              <div
                className=" "
                style={{
                  height: "500px",
                  width: "100%",
                  marginTop: "40px",
                }}
              >
                <Texteditor text={description} setText={setDescription} />
              </div>

              <input
                onClick={refreshPage}
                type="submit"
                class="btn btn-success mr-2"
                value="submit"
              />

              <button className="btn btn-light" onClick={handleCancle}>
                Cancel
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default withSidebar(AddNewsPage);
