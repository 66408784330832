import React, { useState } from "react";
import { deliveryStatusValue } from "../../../utils/delivery";
import OrderMenu from "./OrderMenu";
import { Link } from "react-router-dom";

const OrderStatusList = ({ orders, tableTitle, link }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [activeMenu, setActiveMenu] = useState(null); // Store only the active menu's ID

  const totalPage = Math.ceil(orders?.length / itemsPerPage);
  const pageStarts =
    currentPage > 1 && totalPage - currentPage >= 3
      ? currentPage - 1
      : totalPage < 5
      ? 1
      : currentPage;

  const handleClick = (e) => {
    setCurrentPage(Number(e.target.id));
  };

  const handleIncrement = () => {
    setCurrentPage((prevState) => {
      if (prevState >= orders?.length) return prevState;
      return prevState + 1;
    });
  };
  const handleDecrement = () => {
    setCurrentPage((prevState) => {
      if (prevState <= 0) return prevState;
      return prevState - 1;
    });
  };

  const indexOfLastItem =
    currentPage * itemsPerPage > orders?.length
      ? orders?.length
      : currentPage * itemsPerPage;
  const indexOfFirstItem =
    indexOfLastItem - itemsPerPage < 0 ? 0 : indexOfLastItem - itemsPerPage;
  const currentOrders = orders?.slice(indexOfFirstItem, indexOfLastItem);

  const renderOrders = currentOrders
    ?.map((order, id) => {
      const { delivery, destinationAddress, name } = order || {};

      return (
        <tr key={order?._id}>
          <td>{order?.delivery?.deliveryId}</td>
          <td>{name}</td>
          <td>{destinationAddress}</td>
          <td>{order?.phone}</td>
          <td>{order?.productName || order?.scrapType}</td>
          <td>
            <div className="relative">
              <div
                className={`badge ${
                  order?.delivery?.deliveryStatus === "Delivered"
                    ? "badge-success"
                    : "badge-warning"
                } badge-fw cursor-pointer`}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  setActiveMenu((prevState) =>
                    prevState === order._id ? null : order._id
                  )
                }
              >
                {delivery?.deliveryStatus}
              </div>
              <div className="absolute z-[999] shadow-lg  bg-white" key={id}>
                {activeMenu === order._id && (
                  <OrderMenu
                    menuList={deliveryStatusValue}
                    setOnMenuClick={setActiveMenu}
                    onMenuClick={activeMenu}
                    orderId={order?.delivery?._id}
                  />
                )}
              </div>
            </div>
          </td>
        </tr>
      );
    })
    .reverse();

  const pageNumbers = [];
  for (let i = pageStarts; i <= totalPage; i++) {
    if (pageNumbers?.length > 4) break;
    pageNumbers.push(i);
  }

  const renderPageNumbers = pageNumbers?.map((number) => (
    <li
      key={number}
      className={`page-item ${number === currentPage ? "active" : ""}`}
    >
      <button id={number} className="page-link" onClick={handleClick}>
        {number}
      </button>
    </li>
  ));

  return (
    <div className="row grid-margin">
      <div className="col-12">
        <div className="card">
          <div className="card-body">
            <h6 className="card-title">{tableTitle}</h6>
            <div className="d-flex table-responsive">
              {link && (
                <Link to={link}>
                  <div className="btn-group mr-2">
                    <button className="btn btn-sm btn-info">
                      <i className="mdi mdi-plus-circle-outline" /> Add
                    </button>
                  </div>
                </Link>
              )}
              <div className="btn-group ml-auto mr-2 border-0">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Here"
                />
              </div>
            </div>
            <div className="">
              <table className="table mt-3 border-top">
                <thead>
                  <tr>
                    <th>Tracking ID</th>
                    <th>Customer</th>
                    <th>Address</th>
                    <th>Phone</th>
                    <th>Product Name</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>{renderOrders}</tbody>
              </table>
            </div>
            <div className="d-flex align-items-center justify-content-between flex-column flex-sm-row mt-4">
              <p className="mb-3 mb-sm-0">
                Showing {indexOfFirstItem + 1} to {indexOfLastItem} of{" "}
                {orders?.length} entries
              </p>
              <nav>
                <ul className="pagination pagination-info mb-0">
                  <li className="page-item">
                    <button className="page-link" onClick={handleDecrement}>
                      <i className="mdi mdi-chevron-left" />
                    </button>
                  </li>
                  {renderPageNumbers}
                  <li className="page-item">
                    <button className="page-link" onClick={handleIncrement}>
                      <i className="mdi mdi-chevron-right" />
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderStatusList;
