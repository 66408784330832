import React, { useEffect } from "react";
import Homepage from "../frontpage/Homepage";

import withSidebar from "../../utils/wrapper";

const Adminpage = () => {
  return (
    <>
      <div className="container-fluid page-body-wrapper">
        <div className="row row-offcanvas row-offcanvas-right">
          <Homepage />
        </div>
      </div>
    </>
  );
};

export default withSidebar(Adminpage);
