import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  let token = window.localStorage.getItem("authToken");

  if (!token) {
    return <Navigate to={"/login"} replace />;
  }
  return children;
};

export default PrivateRoute;
