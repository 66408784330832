import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { MantineProvider } from "@mantine/core";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <MantineProvider
      theme={{
        /** You can define your global theme settings here */
        colorScheme: "light", // or 'dark'
        // Add other theme customizations here
      }}
    >
      <App />
    </MantineProvider>
  </BrowserRouter>
);
