import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { apiDelete, apiGet } from "../../../utils/apis";
import withSidebar from "../../../utils/wrapper";
import OrderTable from "../OrderTable";

const AllCustomOrderDetails = () => {
  const [inputs, setInputs] = useState("");
  const getAllData = async () => {
    const res = await apiGet("customOrder");
    const data = await res.data;
    return data;
  };

  useEffect(() => {
    if (!inputs) {
      getAllData().then((data) => setInputs(data.data));
    }
  }, [getAllData]);

  const handleDelete = (id) => {
    apiDelete(`customOrder/${id}`);
    window.location.reload(true);
  };
  return (
    <div class="content-wrapper w-full ">
      <div class="row">
        <div className="col-md-12 grid-margin stretch-card ">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">ALL CUSTOM ORDER LIST</h4>
              <div className="table-responsive"></div>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Delivery Id</th>
                    <th>Phone</th>
                    <th>Product Type</th>
                    <th>Delivery Status</th>
                    <th>Type</th>
                    <th>Action</th>
                  </tr>
                </thead>
                {inputs &&
                  inputs.map((item) => {
                    return (
                      <OrderTable
                        name={item.name}
                        email={item.email}
                        deliveryId={item?.delivery?.deliveryId}
                        phone={item.phone}
                        pType={item.productType}
                        delivery={item?.delivery}
                        type={"customOrder"}
                        productType={item.type}
                        id={item._id}
                      />
                    );
                  })}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withSidebar(AllCustomOrderDetails);
