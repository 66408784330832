import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { apiPost } from "../../utils/apis";

const PostCareer = () => {
  const [inputs, setInputs] = useState({
    title: "",
    jobRequirment: "",
    jobDescription: "",
    skills: "",
    vacancyNo: "",
    validDate: "",
  });

  const handleChange = (e) => {
    setInputs((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const sendRequest = async () => {
    const res = await apiPost("career/addCareer", {
      title: inputs.title,
      jobRequirment: inputs.jobRequirment,
      jobDescription: inputs.jobDescription,
      skills: inputs.skills,
      vacancyNo: inputs.vacancyNo,
      validDate: inputs.validDate,
    }).catch((err) => console.log(err));

    const data = await res.data;
    return data;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendRequest()
      .then((data) => console.log(data))
      .then(() => {
        setInputs({
          title: "",
          jobRequirment: "",
          jobDescription: "",
          skills: "",
          vacancyNo: "",
          validDate: "",
        });
      });
  };
  return (
    <div class="content-wrapper w-full" style={{ background: "#DAEAF1" }}>
      <div class="row">
        <section className="d-flex justify-content-center">
          <div className="col-lg-12 col-xl-11">
            <p className="text-center h4 fw-bold mb-5 mx-1 mx-md-4 mt-4">
              You cannot mandate productivity, you must provide the tools to let
              people become their best.
            </p>
            <div className="card text-black" style={{ borderRadius: 25 }}>
              <div className="card-body p-md-5">
                <div className="row justify-content-center">
                  <div className="col-md-10 col-lg-8 col-xl-5 order-2 order-lg-1">
                    <form className="mx-1 mx-md-4" onSubmit={handleSubmit}>
                      <div className="d-flex flex-row align-items-center mb-4">
                        <div className=" border border-light flex-fill mb-0">
                          <label
                            className="form-label"
                            htmlFor="form3Example1c"
                          >
                            Job Title
                          </label>
                          <input
                            type="text"
                            name="title"
                            value={inputs.title}
                            onChange={handleChange}
                            id="form3Example1c"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="d-flex flex-row align-items-center mb-4">
                        <div className="form-outline flex-fill mb-0">
                          <label
                            className="form-label"
                            htmlFor="form3Example3c"
                          >
                            Job Description
                          </label>
                          <textarea
                            type="text"
                            name="jobDescription"
                            value={inputs.jobDescription}
                            onChange={handleChange}
                            className="form-control"
                            style={{ width: 330, height: 150 }}
                          />
                        </div>
                      </div>
                      <div className="d-flex flex-row align-items-center mb-4">
                        <div className="form-outline flex-fill mb-0">
                          <label
                            className="form-label"
                            htmlFor="form3Example4c"
                          >
                            Requirement
                          </label>
                          <textarea
                            type="text"
                            name="jobRequirment"
                            value={inputs.jobRequirment}
                            onChange={handleChange}
                            className="form-control"
                            style={{ width: 330, height: 150 }}
                          />
                        </div>
                      </div>
                      <div className="d-flex flex-row align-items-center mb-4">
                        <div className="form-outline flex-fill mb-0">
                          <label
                            className="form-label"
                            htmlFor="form3Example4cd"
                          >
                            Required Skills & Qualifications
                          </label>
                          <textarea
                            type="text"
                            name="skills"
                            value={inputs.skills}
                            onChange={handleChange}
                            className="form-control"
                            style={{ width: 330, height: 150 }}
                          />
                        </div>
                      </div>

                      <div className=" border border-light flex-fill mb-0">
                        <label className="form-label">Job Vacancy</label>
                        <input
                          type="text"
                          name="vacancyNo"
                          value={inputs.vacancyNo}
                          onChange={handleChange}
                          id="form3Example1c"
                          className="form-control"
                        />
                      </div>
                      <div className=" border border-light flex-fill mb-0 input-with-post-icon datepicker">
                        <label className="form-label">
                          Last Date For Apply
                        </label>
                        <input
                          type="date"
                          name="validDate"
                          value={inputs.validDate}
                          onChange={handleChange}
                          className="form-control input-with-post-icon datepicker "
                        />
                      </div>
                      <div className="d-flex justify-content-center mx-4 mb-3 mb-lg-4"></div>
                      <button type="submit" className="btn btn-primary btn-lg">
                        Post
                      </button>
                    </form>
                  </div>
                  <div className="col-md-10 col-lg-6 col-xl-7 d-flex flex-column align-items-center justify-content-center order- order-lg-2">
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{
                        marginRight: "auto",
                        marginLeft: "auto",
                        background: "#354259",
                        height: 40,
                        width: 170,
                        textAlign: "center",
                        borderRadius: 10,
                        textDecoration: "none",
                      }}
                    >
                      <Link
                        to="/"
                        className=""
                        style={{
                          textDecoration: "none",
                          color: "#fff",

                          ":hover": {
                            color: "#bbb",
                          },
                        }}
                      >
                        ALL CAREER POST
                      </Link>
                    </div>
                    <img
                      src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-registration/draw1.webp"
                      className="img-fluid"
                      alt="Sample image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default PostCareer;
