import React from "react";
import { useState, useEffect } from "react";
import { staticURL } from "../../config/config";
import { apiDelete, apiGet } from "../../utils/apis";

const BasicTable = () => {
  const [inputs, setInputs] = useState();
  const fetchData = async () => {
    const res = await apiGet("product").catch((err) => console.log(err));

    const data = await res.data;
    return data;
  };

  const deleteItem = (id, e) => {
    apiDelete(`product/${id}`)
      .then(() => alert("Are You Sure?"))
      .then(() => window.location.reload(true))
      .catch((err) => console.log(err));
  };

  //   console.log(inputs);
  useEffect(() => {
    if (!inputs) {
      fetchData().then((data) => {
        setInputs(data.item);
      });
    }
  });

  return (
    <div className="col-md-12 grid-margin stretch-card ">
      <div className="card">
        <div className="card-body">
          <h4 className="card-title">Prodcuts Data</h4>
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Product Title</th>
                  <th>Action</th>
                </tr>
              </thead>
              {inputs &&
                inputs.map((item, index) => (
                  <tbody key={index}>
                    <tr>
                      <td className="py-1">
                        <img
                          src={`${staticURL}/static/${item.image}`}
                          alt={item.title}
                        />
                      </td>
                      <td>{item.title}</td>
                      {/* {(id = item._id)} */}
                      <td className="d-flex flex-row align-items-center">
                        <i
                          className="fa fa-edit"
                          style={{
                            fontSize: 26,
                            marginRight: 10,
                            cursor: "pointer",
                            marginTop: 2,
                            color: "#0000ff",
                          }}
                        ></i>
                        <i
                          className="fa fa-trash-o"
                          style={{
                            fontSize: 26,
                            marginLeft: 10,
                            cursor: "pointer",
                            color: "#ff0000",
                          }}
                          onClick={(e) => deleteItem(item._id, e)}
                        ></i>
                      </td>
                    </tr>
                  </tbody>
                ))}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicTable;
