import React from "react";
import UserListTable from "./UserListTable";
import withSidebar from "../../utils/wrapper";

const AgentList = () => {
  return (
    <div className="container-fluid page-body-wrapper">
      <div className="row row-offcanvas row-offcanvas-right">
        <UserListTable />
      </div>
    </div>
  );
};

export default withSidebar(AgentList);
