import React from "react";
import { useState } from "react";
import BasicTable from "../Basic Table/ProductBasicTable";
import { apiPost } from "../../utils/apis";

const AddProducts = () => {
  const [inputs, setinputs] = useState({
    title: "",
    description: "",
    image: "",
  });

  const formData = new FormData();
  const handleChange = (e) => {
    setinputs((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const refreshPage = () => {
    window.location.reload();
  };

  const handlePhoto = (e) => {
    setinputs({
      ...inputs,
      image: e.target.files[0],
    });
  };

  const sendRequest = async () => {
    formData.append("title", inputs.title);
    formData.append("description", inputs.description);
    formData.append("image", inputs.image);
    const res = await apiPost("product/add", formData).catch((err) =>
      console.log(err)
    );

    const data = await res.data;
    return data;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    sendRequest().then((data) => {
      if (data) {
        refreshPage();
      }
      setinputs({
        title: "",
        description: "",
        image: "",
      });
    });
  };

  const handleCancle = (e) => {
    e.preventDefault();
    setinputs({
      title: "",
      description: "",
      image: "",
    });
  };

  return (
    <div class="content-wrapper w-full py-6">
      <div class="row">
        <div className="col-md-12 grid-margin stretch-card ">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">Upload Product</h4>

              {inputs && (
                <form
                  onSubmit={handleSubmit}
                  className="forms-sample"
                  encType="multipart/form-data"
                  method="post"
                >
                  <div className="form-group">
                    <label htmlFor="exampleInputName1">Title</label>
                    <input
                      type="text"
                      name="title"
                      value={inputs.title}
                      onChange={handleChange}
                      className="form-control"
                      id="exampleInputName1"
                      placeholder="Title"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail3">Description</label>
                    <input
                      type="text"
                      name="description"
                      value={inputs.description}
                      onChange={handleChange}
                      className="form-control"
                      id="exampleInputEmail3"
                      placeholder="Description"
                    />
                  </div>

                  <div className="form-group">
                    <label>File upload</label>
                    <div className="input-group col-xs-12">
                      <input
                        type="file"
                        accept=".png, .jpg, .jpeg"
                        // value={inputs.image}
                        name="image"
                        onChange={handlePhoto}
                        className="form-control file-upload-info"
                        //   disabled
                        placeholder="Upload Image"
                      />
                    </div>
                  </div>

                  {/* <input
                    onClick={refreshPage}
                    type="submit"
                    class="btn btn-success mr-2"
                    value="submit"
                  /> */}

                  <button type="submit" className="btn btn-primary mr-2">
                    Submit
                  </button>
                  <button className="btn btn-light" onClick={handleCancle}>
                    Cancel
                  </button>
                </form>
              )}
            </div>
          </div>
        </div>

        <BasicTable />
      </div>
    </div>
  );
};

export default AddProducts;
