import React, { useState } from "react";
import { apiUpdate } from "../../../utils/apis";

const OrderMenu = ({ menuList, setOnMenuClick, onMenuClick, orderId }) => {
  //   const [onMenuClick, setOnMenuClick] = useState(false);
  const updateDelivery = async (id, deliveryStatus) => {
    const response = await apiUpdate(`delivery/update/${id}`, {
      deliveryStatus,
    });
    if (response.status === 200) {
      window.location.reload();
    }
  };
  return (
    <div>
      <div className="bg-white  text-black py-2 px-6">
        {menuList?.map((item) => {
          return (
            <div
              className="border-b py-2 px-2"
              onClick={() => {
                setOnMenuClick(!onMenuClick);
                updateDelivery(orderId, item?.value);
              }}
              style={{ cursor: "pointer" }}
            >
              {item.value}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default OrderMenu;
