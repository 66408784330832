import React, { useState } from "react";

import {
  Bill,
  BinanceUsd,
  Category2,
  Clipboard,
  Convertshape2,
  Logout,
  Paperclip,
} from "iconsax-react";
import DropDownMenuNew from "./DropDownMenuNew.jsx";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/image/logo.png";

const Sidebar2 = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [openDropdown, setOpenDropdown] = useState(null); // Track the open dropdown
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleLogout = () => {
    window.localStorage.removeItem("authToken");
    window.localStorage.removeItem("user");
    navigate("/login");
  };

  const accountLinks = [
    { label: "Profile", href: "#profile" },
    { label: "Settings", href: "#settings" },
    { label: "Logout", href: "#logout" },
  ];

  const invoiceLinks = [
    { label: "New Invoice", href: "/create-invoice" },
    { label: "View Invoices", href: "/invoice" },
  ];

  const pagesLinks = [
    { label: "Testimonial", href: "/consultation-post" },
    { label: "Agent Details", href: "/agent" },
    { label: "Consultation Details", href: "/consultation" },
    { label: "Quotes", href: "/qoute" },
    { label: "FAQ", href: "/faq" },
    { label: "Newsletter", href: "/newsletter" },
  ];

  const productsLinks = [{ label: "Add Product", href: "/addProducts" }];

  const ordersLink = [
    { label: "Pipe", href: "/pipe-order" },
    { label: "Scraps", href: "/scrap-order" },
    { label: "Custom Orders", href: "/custom-order" },
  ];

  const careerLinks = [
    { label: "Add Career", href: "/career" },
    { label: "View Career", href: "/career-list" },
  ];

  const newsLinks = [
    { label: "Add News", href: "/add-news" },
    { label: "View News", href: "/all-news" },
  ];

  return (
    <div className="flex w-full">
      <aside
        id="sidebar-multi-level-sidebar"
        className={`fixed top-0 left-0 z-40 h-screen transition-transform transform ${
          isSidebarOpen ? "translate-x-0 w-[17%]" : "-translate-x-3 w-[4rem]"
        } sm:translate-x-0 bg-blue-50`}
        aria-label="Sidebar"
      >
        <div className={`bg-blue-50 mt-6`}>
          {isSidebarOpen && (
            <img src={logo} alt="logo" className="h-20 mx-auto " />
          )}
        </div>
        <div className="h-full px-3 py-4 overflow-y-auto no-scrollbar">
          <ul className="space-y-2 font-medium">
            <li>
              <Link
                to={"/"}
                className="flex items-center w-full p-2 text-base text-gray-500 transition duration-75 rounded-lg group hover:text-gray-700 hover:bg-gray-100"
                style={{ textDecoration: "none" }}
              >
                <svg
                  className="w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-900"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 22 21"
                >
                  <path d="M16.975 11H10V4.025a1 1 0 0 0-1.066-.998 8.5 8.5 0 1 0 9.039 9.039.999.999 0 0 0-1-1.066h.002Z" />
                  <path d="M12.5 0c-.157 0-.311.01-.565.027A1 1 0 0 0 11 1.02V10h8.975a1 1 0 0 0 1-.935c.013-.188.028-.374.028-.565A8.51 8.51 0 0 0 12.5 0Z" />
                </svg>
                {isSidebarOpen && <span className="ms-3">Dashboard</span>}
              </Link>
            </li>

            <DropDownMenuNew
              label="Invoice"
              links={invoiceLinks}
              icon={<Bill size={20} variant="Bulk" className="text-gray-800" />}
              isOpen={openDropdown === "Invoice"}
              setOpenDropdown={setOpenDropdown}
              currentDropdown="Invoice"
            />
            <DropDownMenuNew
              label="Pages"
              links={pagesLinks}
              icon={
                <BinanceUsd
                  size={20}
                  variant="Bulk"
                  className="text-gray-800"
                />
              }
              isOpen={openDropdown === "Pages"}
              setOpenDropdown={setOpenDropdown}
              currentDropdown="Pages"
            />
            <DropDownMenuNew
              label="Products"
              links={productsLinks}
              icon={
                <Category2 size={20} variant="Bulk" className="text-gray-800" />
              }
              isOpen={openDropdown === "Products"}
              setOpenDropdown={setOpenDropdown}
              currentDropdown="Products"
            />
            <DropDownMenuNew
              label="Orders"
              links={ordersLink}
              icon={
                <Clipboard size={20} variant="Bulk" className="text-gray-800" />
              }
              isOpen={openDropdown === "Orders"}
              setOpenDropdown={setOpenDropdown}
              currentDropdown="Orders"
            />
            <DropDownMenuNew
              label="News"
              links={newsLinks}
              icon={
                <Paperclip size={20} variant="Bulk" className="text-gray-800" />
              }
              isOpen={openDropdown === "News"}
              setOpenDropdown={setOpenDropdown}
              currentDropdown="News"
            />

            <DropDownMenuNew
              label="Career"
              links={careerLinks}
              icon={
                <Convertshape2
                  size={20}
                  variant="Bulk"
                  className="text-gray-800"
                />
              }
              isOpen={openDropdown === "Career"}
              setOpenDropdown={setOpenDropdown}
              currentDropdown="Career"
            />

            <li
              className="flex items-center p-2 cursor-pointer text-gray-900 rounded-lg hover:bg-gray-100  group"
              onClick={handleLogout}
            >
              <Logout size={20} variant="Bulk" className="text-gray-800" />
              {isSidebarOpen && (
                <span className="flex-1 ms-3 whitespace-nowrap">Logout</span>
              )}
            </li>
          </ul>
        </div>
      </aside>
    </div>
  );
};

export default Sidebar2;
