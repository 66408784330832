import { DateTime } from "luxon";

export const convertToDateString = (date) => {
  const year = new Date(date).getFullYear();
  const month = (new Date(date).getMonth() + 1).toString(); // Add 1 to month
  const day = new Date(date).getDate().toString(); // Use getDate() instead of getDay()
  const output = `${day.length === 1 ? "0" + day : day}/${
    month.length === 1 ? "0" + month : month
  }/${year}`;
  return output;
};

export const convertToDate = (date) => {
  return new Date(date);
};

export const checkDateDiff = (start, end) => {
  const diff = end - start;
  return diff / 1000;
};

export const getReadableStringDate = (date, skipDay) => {
  const output = new Date(date).toDateString().split(" ");
  const dateToYear = `${output[1]} ${output[2]}, ${output[3]}`;
  const str = skipDay ? dateToYear : `${output[0]}, ${dateToYear}`;

  return str;
};

export const getDateFormatAsDateMonthYear = (date) => {
  const output = new Date(date).toDateString().split(" ");
  const dateToYear = `${output[2]} ${output[1]} ${output[3]}`;
  const str = dateToYear;

  return str;
};

export const dateDiff = (start, end) => {
  const d1 = DateTime.fromISO(new Date(start).toISOString());
  const d2 = DateTime.fromISO(new Date(end).toISOString());
  const diff = d2.diff(d1, ["years", "months", "days", "hours"]);
  return diff.toObject();
};

export const convertDateFormat = (dateString) => {
  // Create a Date object from the provided string
  const date = new Date(dateString);

  // Convert the date to YYYY-MM-DD format
  const formattedDate = date.toISOString().split("T")[0];

  return formattedDate;
};
