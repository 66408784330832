import React from "react";
import AddProducts from "../Products/AddProducts";
import withSidebar from "../../utils/wrapper";

const AddProdutsPage = () => {
  return (
    <div className="container-fluid page-body-wrapper">
      <div className="row row-offcanvas row-offcanvas-right">
        <AddProducts />
      </div>
    </div>
  );
};

export default withSidebar(AddProdutsPage);
