import React from "react";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import OrderTable from "../OrderTable";
import { apiGet } from "../../../utils/apis";

const ScrapOrderTable = () => {
  const [inputs, setInputs] = useState();

  const fetchData = async () => {
    const res = await apiGet("scrap").catch((err) => console.log(err));

    const data = res.data;
    return data;
  };

  useEffect(() => {
    fetchData().then((data) => {
      if (!inputs) {
        setInputs(data.order);
      }
    });
  });
  return (
    <div class="content-wrapper w-full ">
      <div class="row">
        <div className="col-md-12 grid-margin stretch-card ">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">SCRAP ORDERS</h4>
              <div className="table-responsive"></div>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Delivery Id</th>
                    <th>Phone</th>
                    <th>Product Type</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                {inputs &&
                  inputs
                    ?.reverse()
                    .map((item) => (
                      <OrderTable
                        name={item.name}
                        email={item.email}
                        phone={item.phone}
                        deliveryId={item?.delivery?.deliveryId}
                        delivery={item?.delivery}
                        pType={item.scrapType}
                        type="scrap"
                        id={item._id}
                      />
                    ))}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScrapOrderTable;
