import React from "react";
import { useState } from "react";
import axios from "axios";
import OrderDetails from "../OrderDetails";
import { useEffect } from "react";
import { apiGet } from "../../../utils/apis";

const PIpeOrder = () => {
  const [inputs, setInputs] = useState();
  const id = window.location.href.split("/")[4];
  console.log(id);

  const fetchData = async () => {
    const res = await apiGet(`pipe/user/${id}`).catch((err) =>
      console.log(err)
    );

    const data = await res.data;
    return data;
  };
  useEffect(() => {
    if (!inputs) {
      fetchData().then((data) => setInputs(data.order));
    }
  });
  return (
    <>
      {inputs && (
        <OrderDetails
          name={inputs.name}
          email={inputs.email}
          phone={"0" + inputs.phone}
          cName={inputs.companyName}
          cAddress={inputs.companyAddress}
          city={inputs.cityName}
          pName={inputs.productName}
          pType={inputs.productType}
          thickness={inputs.thickness}
          pQuantity={inputs.productQuantity}
          dAddress={inputs.destinationAddress}
          message={inputs.message}
          isShown={true}
        />
      )}
    </>
  );
};

export default PIpeOrder;
