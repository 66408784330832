import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { apiDelete, apiGet } from "../../../utils/apis";
import { getReadableStringDate } from "../../../utils/dates";
import withSidebar from "../../../utils/wrapper";

const AllNewsPage = () => {
  const [data, setData] = useState("");
  const navigate = useNavigate();
  const handleDelete = (id, e) => {
    apiDelete(`news/delete/${id}`)
      .then(() => {
        window.location.reload(true);
      })
      .catch((err) => console.log(err));
  };

  const fetchData = async () => {
    const res = await apiGet("news");
    const data = await res.data;
    return data;
  };

  const handleNavigate = (id) => {
    navigate(`/update-news/${id}`);
  };

  useEffect(() => {
    fetchData()
      .then((data) => setData(data.news))
      .then((err) => console.log(err));
  }, []);

  return (
    <div className="container-fluid px-1 py-5 mx-auto mh-100">
      <div className="row d-flex justify-content-center mh-100">
        <div className="col-xl-12 col-lg-8 col-md-9 col-11 mb-4">
          <div className="card-body">
            <h4 className="card-title">News</h4>
            <div className="table-responsive"></div>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Publish Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              {data.length
                ? data.map((item, idx) => {
                    return (
                      <tbody>
                        <tr key={idx}>
                          <td>{item.title}</td>
                          <td>{getReadableStringDate(item.date)}</td>

                          <td className="d-flex flex-row align-items-center">
                            <i
                              className="fa fa-edit"
                              onClick={(e) => handleNavigate(item._id, e)}
                              style={{
                                fontSize: 26,
                                marginRight: 10,
                                cursor: "pointer",
                                marginTop: 2,
                                color: "#0000ff",
                              }}
                            ></i>
                            <i
                              className="fa fa-trash-o"
                              onClick={(e) => handleDelete(item._id, e)}
                              style={{
                                fontSize: 26,
                                marginLeft: 10,
                                cursor: "pointer",
                                color: "#ff0000",
                              }}
                            ></i>
                          </td>
                        </tr>
                      </tbody>
                    );
                  })
                : "No data found"}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withSidebar(AllNewsPage);
