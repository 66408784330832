import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";

import { useState } from "react";
import { useEffect } from "react";
import { apiGet } from "../utils/apis";
import Adminpage from "../components/pages/Adminpage";
import AddProdutsPage from "../components/pages/AddProdutsPage";
import Pipe from "../components/Order/Pipe/Pipe";
import PIpeOrder from "../components/Order/Pipe/PIpeOrder";
import Scrap from "../components/Order/Scrap/Scrap";
import ScrapOrder from "../components/Order/Scrap/ScrapOrder";
import Career from "../components/Career/Career";
import AllCareer from "../components/Career/AllCareer";
import EidtCareer from "../components/Career/EidtCareer";
import AgentList from "../components/Order/Agent/AgentList";
import AgentDetails from "../components/Order/Agent/AgentDetails";
import ConsultationDetails from "../components/Consultation/ConsultationDetails";
import ConsultationEdit from "../components/Consultation/ConsultationEdit";
import TestimonialPost from "../components/Testimonial/TestimonialPost";
import AddNewsPage from "../components/pages/news/index";
import AllBlogPage from "../components/pages/news/allBlogPage";
import NewsView from "../components/pages/news/news-view";
import Qoutes from "../components/pages/qoutes";
import Newsletter from "../components/pages/newsletter";
import Faq from "../components/pages/faq";
import Settings from "../components/Settings";
import PrivateRoute from "../components/PrivateRoute";
import AddCustomOrder from "../components/Order/add-custome-order";
import AllCustomOrderDetails from "../components/Order/add-custome-order/all-custom-order";
import NewUser from "../components/pages/NewUser";
import UserList from "../components/UserList/UserList";
import Invoice from "../components/invoice/Invoice";
import InvoiceList from "../components/invoice/invoice-list";
import UserProfile from "../components/UserList/UserProfile";

const PrivateScreen = () => {
  const [privateData, setPrivateData] = useState();
  const navigation = useNavigate();
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchPrivateData = async () => {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      };

      try {
        const { data } = await apiGet("private", config);
        setPrivateData(data.data);
      } catch (error) {
        localStorage.removeItem("authToken");
        setError("You are not authorized please login");
        navigation("/login");
      }
    };
    fetchPrivateData();
  }, []);

  return (
    <div>
      <Routes>
        {/* <Route path="/login" element={<Login />} /> */}
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Adminpage />
            </PrivateRoute>
          }
        />
        <Route
          path="/addProducts"
          element={
            <PrivateRoute>
              <AddProdutsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/pipe-order"
          element={
            <PrivateRoute>
              <Pipe />
            </PrivateRoute>
          }
        />
        <Route
          path="/pipe-order-details/:id"
          element={
            <PrivateRoute>
              <PIpeOrder />
            </PrivateRoute>
          }
        />
        <Route
          path="/scrap-order"
          element={
            <PrivateRoute>
              <Scrap />
            </PrivateRoute>
          }
        />
        <Route
          path="/invoice"
          element={
            <PrivateRoute>
              <InvoiceList />
            </PrivateRoute>
          }
        />
        <Route
          path="/create-invoice"
          element={
            <PrivateRoute>
              <Invoice />
            </PrivateRoute>
          }
        />
        <Route
          path="/scrap-order-details/:id"
          element={
            <PrivateRoute>
              <ScrapOrder />
            </PrivateRoute>
          }
        />
        <Route
          path="/career"
          element={
            <PrivateRoute>
              <Career />
            </PrivateRoute>
          }
        />
        <Route
          path="/career-list"
          element={
            <PrivateRoute>
              <AllCareer />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-career/:id"
          element={
            <PrivateRoute>
              <EidtCareer />
            </PrivateRoute>
          }
        />
        <Route
          path="/agent"
          element={
            <PrivateRoute>
              <AgentList />
            </PrivateRoute>
          }
        />
        <Route
          path="/agent-details/:id"
          element={
            <PrivateRoute>
              <AgentDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="/consultation"
          element={
            <PrivateRoute>
              <ConsultationDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="/cousultation-details/:id"
          element={
            <PrivateRoute>
              <ConsultationEdit />
            </PrivateRoute>
          }
        />
        <Route
          path="/consultation-post"
          element={
            <PrivateRoute>
              <TestimonialPost />
            </PrivateRoute>
          }
        />
        <Route
          path="/add-news"
          element={
            <PrivateRoute>
              <AddNewsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/all-news"
          element={
            <PrivateRoute>
              <AllBlogPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/update-news/:id"
          element={
            <PrivateRoute>
              <NewsView />
            </PrivateRoute>
          }
        />
        <Route
          path="/qoute"
          element={
            <PrivateRoute>
              <Qoutes />
            </PrivateRoute>
          }
        />
        <Route
          path="/newsletter"
          element={
            <PrivateRoute>
              <Newsletter />
            </PrivateRoute>
          }
        />
        <Route
          path="/faq"
          element={
            <PrivateRoute>
              <Faq />
            </PrivateRoute>
          }
        />
        <Route
          path="/custom-order"
          element={
            <PrivateRoute>
              <AddCustomOrder />
            </PrivateRoute>
          }
        />
        <Route
          path="/all-custom-order"
          element={
            <PrivateRoute>
              <AllCustomOrderDetails />
            </PrivateRoute>
          }
        />
        <Route
          path="/new-user"
          element={
            <PrivateRoute>
              <NewUser />
            </PrivateRoute>
          }
        />
        <Route
          path="/user-list"
          element={
            <PrivateRoute>
              <UserList />
            </PrivateRoute>
          }
        />
        <Route path="/user-profile" element={<UserProfile />} />
      </Routes>
    </div>
  );
};

export default PrivateScreen;
