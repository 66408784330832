import React from "react";
import AgentListTable from "./AgentListTable";
import withSidebar from "../../../utils/wrapper";

const AgentList = () => {
  return (
    <div className="container-fluid page-body-wrapper">
      <div className="row row-offcanvas row-offcanvas-right">
        <AgentListTable />
      </div>
    </div>
  );
};

export default withSidebar(AgentList);
