import React from "react";

const PrimaryInput = ({
  label,
  type = "text",
  id,
  name,
  placeholder,
  value,
  onChange,
  required = false,
  ...props
}) => {
  return (
    <div className="w-full mb-6">
      <label
        htmlFor={id}
        className="block text-sm text-start font-medium text-gray-700 mb-2"
      >
        {label}
        {required && <span className="text-red-500 ml-1">*</span>}
      </label>
      <input
        type={type}
        id={id}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className="mt-1 block w-full px-4 py-2 border border-blue-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-gray-900 placeholder-gray-400"
        required={required}
        {...props}
      />
    </div>
  );
};

export default PrimaryInput;
