import React, { useCallback } from "react";
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "./editor-toolbar";
import "react-quill/dist/quill.snow.css";

const Texteditor = ({ text, setText, isEmpty }) => {
  const changeValue = useCallback(
    (value) => {
      setText(value);
    },
    [setText]
  );

  return (
    <div className={`mt-12 mb-12`}>
      <div className="w-100 h-100 ">
        <EditorToolbar toolbarId={"t1"} />
        <ReactQuill
          className="text-sm h-48"
          style={{
            height: "400px",
            maxHeight: "600px",
          }}
          theme="snow"
          value={text}
          onChange={(e) => changeValue(e)}
          placeholder={"Write something awesome..."}
          modules={modules("t1")}
          formats={formats}
        />
        {isEmpty && text.length < 10 && (
          <div className="aboslute z-10 mt-2 text-sm text-red-500">
            News description must contain at least 50 characters.
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(Texteditor);
