import React from "react";
import { useState } from "react";
import axios from "axios";

import { useEffect } from "react";
import TableComponent from "../../Table/TableComponent";
import Sidebar from "../../Nav/Sidebar";
import { apiGet } from "../../../utils/apis";
import { config } from "../../../utils/config";

const AgentDetails = () => {
  const [inputs, setInputs] = useState();
  const id = window.location.href.split("/")[4];
  console.log(id);

  const fetchData = async () => {
    const res = await apiGet(`agent/list/${id}`, config).catch((err) =>
      console.log(err)
    );

    const data = await res.data;
    return data;
  };
  useEffect(() => {
    if (!inputs) {
      fetchData().then((data) => setInputs(data.item));
    }
  });
  return (
    <div className="container-fluid page-body-wrapper">
      <div className="row row-offcanvas row-offcanvas-right">
        <Sidebar />
        <div class="content-wrapper ">
          <div class="row">
            <div className="container-fluid px-1 py-5 mx-auto">
              <div className="row d-flex justify-content-center">
                <div className="col-xl-7 col-lg-8 col-md-9 col-11 text-center">
                  <h3>CONSULTATION DETAILS</h3>
                  <p className="blue-text">
                    HEY!! YOU HAVE NEW CONSULTATION!!!
                  </p>
                  {inputs && (
                    <div className="card">
                      <h5 className="text-center mb-4 mt-4">
                        CUSTOMER DETAILS
                      </h5>

                      <TableComponent
                        name={inputs.name}
                        email={inputs.email}
                        phone={inputs.phone}
                        companyName={inputs.companyName}
                        date={inputs.date}
                        message={inputs.message}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentDetails;
