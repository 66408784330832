import React, { useState, useEffect } from "react";
import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import logo from "../../assets/image/logo.png";
import ItemList from "./invoice-components/ItemList";
import CustomerSelector from "./invoice-components/CustomerSelector";
import InvoiceStatus from "./invoice-components/InvoiceStatus";
import DiscountAndRemark from "./invoice-components/DiscountAndRemark";
import { apiGet, apiPost, apiUpdate } from "../../utils/apis";
import { toast } from "react-toastify";
import { convertToDateString } from "../../utils/dates";
import { Link } from "react-router-dom";
import withSidebar from "../../utils/wrapper";

const Invoice = () => {
  const [items, setItems] = useState([
    { description: "", amount: 0, itemName: "", quantityPcs: 0, quantityKg: 0 },
  ]);

  const user = JSON.parse(localStorage.getItem("user"));
  const username = user?.name;

  const queryParam = new URLSearchParams(window.location.search);
  const invoiceId = queryParam.get("invoiceID");

  const [customer, setCustomer] = useState({
    name: "",
    address: "",
    companyName: "",
    email: "",
    phone: "",
  });
  const [issuedBy, setIssuedBy] = useState(username);
  const [basicDetails, setBasicDetails] = useState({
    date: new Date(),
    dueDate: new Date(),
    truckDriverName: "",
    truckRef: "",
    truckDriverPhone: null,
  });
  const [status, setStatus] = useState("due");
  const [remark, setRemark] = useState("");
  const [discount, setDiscount] = useState(0);
  const [advance, setAdvance] = useState(0);
  const [subTotal, setSubtotal] = useState(0);
  const [due, setDue] = useState(0);
  const [invoiceNo, setInvoiceNo] = useState("");

  const addItem = () => {
    setItems([...items, { description: "", amount: 0 }]);
  };

  const removeItem = (index) => {
    setItems(items.filter((_, i) => i !== index));
  };

  const handleItemChange = (index, field, value) => {
    const updatedItems = items.map((item, i) =>
      i === index
        ? { ...item, [field]: field === "amount" ? parseFloat(value) : value }
        : item
    );
    setItems(updatedItems);
  };

  const fetchInvoiceData = async () => {
    try {
      const res = await apiGet(`invoice/${invoiceId}`);
      const data = res.data.invoice;

      if (data) {
        setItems(data.items || []);

        setCustomer({
          name: data.customer?.name || "",
          address: data.customer?.address || "",
          companyName: data.customer?.companyName || "",
          email: data.customer?.email || "",
          phone: data.customer?.phone || "",
        });

        // Ensure basic details fields match
        setBasicDetails({
          date: data.basicDetails?.date
            ? new Date(data.basicDetails.date)
            : new Date(),
          dueDate: data.basicDetails?.dueDate
            ? new Date(data.basicDetails.dueDate)
            : new Date(),
          truckDriverName: data.basicDetails?.truckDriverName || "",
          truckRef: data.basicDetails?.truckRef || "",
          truckDriverPhone: data.basicDetails?.truckDriverPhone || null,
        });

        // Set other fields
        setStatus(data.status || "due");
        setRemark(data.remark || "");
        setDiscount(data.discount || 0);
        setAdvance(data.advance || 0);
        setInvoiceNo(data?.invoiceId);
        setIssuedBy(data.issuedBy || username);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (invoiceId) {
      fetchInvoiceData();
    }
  }, [invoiceId]);


  const handleBasicDetails = (field, value) => {
    setBasicDetails({ ...basicDetails, [field]: value });
  };

  const handleCustomerChange = (field, value) => {
    setCustomer({ ...customer, [field]: value });
  };
  const calculateTotal = () => {
    const total = items.reduce((acc, { amount }) => acc + amount, 0);
    const discountAmount = (total * discount) / 100;
    const calculatedDue = total - discountAmount - advance;
    setSubtotal(total);
    setDue(calculatedDue);
  };

  useEffect(() => {
    calculateTotal();
  }, [items, discount, advance]);

  const saveInvoice = async () => {
    if (
      customer.name !== "" &&
      customer.phone !== "" &&
      customer.address !== ""
    ) {
      try {
        const invoiceData = {
          items,
          customer,
          basicDetails,
          status,
          remark,
          discount,
          advance,
          subTotal,
          due,
          issuedBy,
        };

        const res = await apiPost("invoice/addInvoice", invoiceData);
        if (res?.data) {
          setInvoiceNo(res.data.invoice.invoiceId);
          toast.success("Invoice saved successfully!");
        }
      } catch (error) {
        toast.error("Failed to save invoice. Please try again.", error);
      }
    } else {
      toast.error("Please fill all the fields");
    }
  };

  const updateInvoice = async () => {
    try {
      const invoiceData = {
        items,
        customer,
        basicDetails,
        status,
        remark,
        discount,
        advance,
        total: calculateTotal(),
      };

      const res = await apiUpdate(`invoice/${invoiceId}`, invoiceData);
      if (res?.data) {
        toast.success("Invoice updated successfully!");
      }
    } catch (error) {
      toast.error("Failed to update invoice. Please try again.", error);
    }
  };

  const styles = StyleSheet.create({
    page: {
      padding: 24,
      fontSize: 12,
    },
    headerSection: {
      marginBottom: 16,
      borderBottom: "1px solid #E4E4E4",
    },
    image: {
      width: 100,
      height: 50,
      marginBottom: 8,
    },
    companyInfoSection: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    businessName: {
      fontSize: 14,
      fontWeight: "bold",
      textTransform: "uppercase",
      marginBottom: 8,
    },
    invoiceNumber: {
      marginTop: 12,
      fontSize: 14,
      fontWeight: "bold",
      color: "#13B5EA",
      marginBottom: 8,
    },
    date: {
      fontSize: 12,
      marginBottom: 4,
    },
    dueDate: {
      fontSize: 12,
      marginBottom: 4,
    },
    reference: {
      fontSize: 12,
      marginBottom: 4,
    },
    sectionContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: 16,
      borderBottom: "1px solid #E4E4E4",
      paddingTop: 8,
      paddingBottom: 24,
    },
    companyDetails: {
      flex: 1,
      marginRight: 16,
    },
    companyTitle: {
      fontWeight: "bold",
      marginBottom: 8,
    },
    billToSection: {
      flex: 1,
    },
    billToTitle: {
      fontWeight: "bold",
      marginBottom: 8,
    },
    tableHeader: {
      flexDirection: "row",
      borderBottom: "2px solid #13B5EA",
      paddingBottom: 16,
      marginBottom: 16,
    },
    tableCell: {
      flex: 1,
      fontSize: 12,
    },
    tableRow: {
      flexDirection: "row",
      borderBottom: "1px solid #E4E4E4",
      marginBottom: 4,
      padding: 4,
    },
    subtotal: {
      fontSize: 14,
      fontWeight: "bold",
      textAlign: "right",
    },
    total: {
      fontSize: 18,
      fontWeight: "bold",
      textAlign: "right",
      marginTop: 8,
    },
    textStyle: {
      fontSize: 10,
    },
    signatureSection: {
      marginTop: 32,
      borderTop: "1px solid #E4E4E4",
      paddingTop: 8,
    },
    signatureLabel: {
      fontSize: 12,
      marginBottom: 4,
    },
    signature: {
      fontSize: 14,
      fontWeight: "bold",
      marginBottom: 16,
    },
    footer: {
      marginTop: 32,
      paddingTop: 8,
      position: "absolute",
      bottom: 24,
      left: 24,
      right: 24,
    },
    footerText: {
      fontSize: 12,
      textAlign: "center",
      fontWeight: "bold",
    },
    footerSubText: {
      fontSize: 10,
      color: "#888",
      textAlign: "center",
    },
    totalSection: {
      marginTop: 34,
      textAlign: "right",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
    },
    totalRow: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "40%", // Adjust this value to control the width of the section
    },
    totalLabel: {
      fontSize: 12,
      fontWeight: "bold",
    },
    totalValue: {
      fontSize: 12,
      fontWeight: "small",
      textAlign: "right",
    },
  });

  const InvoiceDocument = (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.headerSection}>
          <View style={styles.companyInfoSection}>
            <View>
              <Image src={logo} style={styles.image} />
            </View>
            <View>
              <Text style={styles.businessName}>Sheikh Tube Mills LTD.</Text>
              <Text style={{ fontSize: 8 }}>
                Email: Info@sheikhtubemills.com
              </Text>
              <Text style={{ fontSize: 8 }}>Phone: +880 1843507243</Text>
              <Text style={{ fontSize: 8 }}>
                Karnafuly Thana Road Ward No:3 <br /> Charlakkah, Chittagong.
              </Text>

              <Text style={styles.invoiceNumber}>Invoice #{invoiceNo}</Text>
            </View>
          </View>

          <Text style={styles.date}>
            Issue Date: {convertToDateString(basicDetails.date)}
          </Text>
          <Text style={styles.dueDate}>
            Due Date: {convertToDateString(basicDetails.dueDate)}
          </Text>
          <Text style={styles.reference}>Reference: {invoiceNo}</Text>
        </View>

        <View style={styles.sectionContainer}>
          <View style={styles.billToSection}>
            <Text style={styles.billToTitle}>BILL TO</Text>
            <Text style={styles.textStyle}>{customer.name}</Text>
            <Text style={styles.textStyle}>{customer.companyName}</Text>
            <Text style={styles.textStyle}>{customer.address}</Text>
            <Text style={styles.textStyle}>{customer.phone}</Text>
            <Text style={styles.textStyle}>{customer.email}</Text>
          </View>
          <View style={styles.companyDetails}>
            <Text style={styles.companyTitle}>Other Details</Text>
            <Text style={styles.textStyle}>
              Truck Driver: {basicDetails.truckDriverName}
            </Text>
            <Text style={styles.textStyle}>
              Truck phone: {basicDetails.truckDriverPhone}
            </Text>
          </View>
        </View>

        <View style={styles.tableHeader}>
          <Text style={styles.tableCell}>Product Name</Text>
          <Text style={styles.tableCell}>Description</Text>
          <Text style={styles.tableCell}>Quantity (PCS)</Text>
          <Text style={styles.tableCell}>Quantity (KGS)</Text>
          <Text style={styles.tableCell}>Price (BDT)</Text>
        </View>

        {items.map((item, index) => (
          <View key={index} style={styles.tableRow}>
            <Text style={styles.tableCell}>{item.itemName}</Text>
            <Text style={styles.tableCell}>{item.description}</Text>
            <Text style={styles.tableCell}>{item.quantityPcs}</Text>
            <Text style={styles.tableCell}>{item.quantityKg}</Text>
            <Text style={styles.tableCell}>{item.amount.toFixed(2)}</Text>
          </View>
        ))}
        {/*    */}
        <View style={styles.totalSection}>
          <View style={styles.totalRow}>
            <Text style={styles.totalLabel}>Sub Total</Text>
            <Text style={styles.totalValue}>
              {items.reduce((acc, { amount }) => acc + amount, 0).toFixed(2)}
            </Text>
          </View>
          <View style={styles.totalRow}>
            <Text style={styles.totalLabel}>Discount</Text>
            <Text style={styles.totalValue}>
              {(
                items.reduce((acc, { amount }) => acc + amount, 0).toFixed(2) *
                (discount / 100)
              ).toFixed(2)}
            </Text>
          </View>
          <View style={styles.totalRow}>
            <Text style={styles.totalLabel}>Advance</Text>
            <Text style={styles.totalValue}>{advance.toFixed(2)}</Text>
          </View>
          <View style={styles.totalRow}>
            <Text style={styles.totalLabel}>Due</Text>
            <Text style={styles.totalValue}>BDT {due.toFixed(2)}</Text>
          </View>

          <View style={styles.totalRow}>
            <Text style={styles.totalLabel}>Payment Status</Text>
            <Text style={(styles.totalValue, { textTransform: "uppercase" })}>
              {status}
            </Text>
          </View>
        </View>

        <View style={styles.footer}>
          <View style={styles.signatureSection}>
            <Text style={styles.signatureLabel}>Issued by, signature:</Text>
            <Text style={styles.signature}>{issuedBy}</Text>
          </View>
          <View>
            <Text style={styles.footerText}>Powered by Dellly</Text>
            <Text style={styles.footerSubText}>
              Visit: www.dellly.com or Call: 01778726988
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );

  const handleClearFeild = () => {
    setItems([
      {
        description: "",
        amount: 0,
        itemName: "",
        quantityPcs: 0,
        quantityKg: 0,
      },
    ]);
    setCustomer({
      name: "",
      address: "",
      companyName: "",
      email: "",
      phone: "",
    });
    setBasicDetails({
      date: new Date(),
      dueDate: new Date(),
      truckDriverName: "",
      truckRef: "",
      truckDriverPhone: "",
    });
    setStatus("due");
    setRemark("");
    setDiscount(0);
    setAdvance(0);
    setSubtotal(0);
    setDue(0);
    setInvoiceNo("");
  };

  useEffect(() => {
    if (!invoiceId) {
      handleClearFeild();
    }
  }, [invoiceId]);

  return (
    <div className="px-6 py-6">
      <div className="">
        <div className="w-full">
          <div className="row">
            <div className="">
              <div className="flex justify-between items-center">
                <h1 className="text-2xl font-bold mb-4">
                  Invoice {invoiceNo && `#${invoiceNo}`}
                </h1>
                <div className="flex items-center gap-2">
                  <Link to={"/invoice"}>
                    <div
                      className="border inline-block px-2 py-2 cursor-pointer text-center hover:text-purple-200  bg-purple-500 text-white"
                      onClick={handleClearFeild}
                    >
                      Invoices
                    </div>{" "}
                  </Link>
                  {invoiceNo && (
                    <div className="flex items-center gap-2">
                      <div className="border px-2 py-2 text-center hover:text-purple-200 hover:inline  focus:outline-none bg-purple-500 text-white">
                        <PDFDownloadLink
                          document={InvoiceDocument}
                          fileName={invoiceNo}
                          className="text-white appearance-none inline-block"
                        >
                          {({ loading }) =>
                            loading ? "Generating PDF..." : "Download PDF"
                          }
                        </PDFDownloadLink>
                      </div>

                      {!invoiceId ? (
                        <div
                          className="border px-2 py-2 cursor-pointer text-center hover:text-purple-200 inline-block bg-purple-500 text-white"
                          onClick={handleClearFeild}
                        >
                          Create New
                        </div>
                      ) : (
                        <Link to={"/create-invoice"}>
                          {" "}
                          <div
                            className="border px-2 py-2 cursor-pointer text-center hover:text-purple-200  bg-purple-500 text-white"
                            onClick={handleClearFeild}
                          >
                            Create New
                          </div>
                        </Link>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <CustomerSelector
                customer={customer}
                setCustomer={setCustomer}
                handleBasicDetails={handleBasicDetails}
                handleCustomerChange={handleCustomerChange}
                basicDetails={basicDetails}
              />
              <div className="flex gap-2 justify-between py-4 bg-white">
                <div className="lg:w-[80%] py-2 px-4 flex flex-col justify-between">
                  <ItemList
                    items={items}
                    handleItemChange={handleItemChange}
                    addItem={addItem}
                    removeItem={removeItem}
                  />

                  <div className="mb-4 py-2 px-3">
                    <label className="block text-sm font-medium">Remark</label>
                    <textarea
                      className="mt-1 block p-2 w-full h-full rounded-md bg-gray-100 border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      value={remark}
                      onChange={(e) => setRemark(e.target.value)}
                    />
                  </div>
                </div>
                <div className="bg-white  border-l-2  p-10 lg:w-[20%]">
                  <div className="flex h-full flex-col justify-between">
                    <div>
                      <DiscountAndRemark
                        discount={discount}
                        setDiscount={setDiscount}
                        remark={remark}
                        setRemark={setRemark}
                        advance={advance}
                        setAdvance={setAdvance}
                      />
                      <InvoiceStatus status={status} setStatus={setStatus} />
                      <div>
                        <div className="flex items-center justify-between">
                          <p className="text-sm font-bold">Subtotal</p>
                          <p>{subTotal?.toFixed(2)}</p>
                        </div>
                        <div className="flex items-center justify-between">
                          <p className="text-sm font-bold">Advance</p>
                          <p>{advance?.toFixed(2)}</p>
                        </div>
                        <div className="flex items-center justify-between">
                          <p className="text-sm font-bold">Discount </p>{" "}
                          <p>
                            {(
                              items
                                .reduce((acc, { amount }) => acc + amount, 0)
                                .toFixed(2) *
                              (discount / 100)
                            ).toFixed(2)}
                          </p>
                        </div>
                        <hr className="py-2" />
                        <div className="flex items-center justify-between">
                          <p className="text-sm font-bold">Due</p>
                          <p> BDT {due.toFixed(2)} </p>
                        </div>
                      </div>
                    </div>
                    {invoiceNo === "" ? (
                      <button
                        className="bg-purple-500 text-white px-4 py-2 mt-4"
                        onClick={saveInvoice}
                      >
                        Save Invoice
                      </button>
                    ) : (
                      <button
                        className="bg-purple-500 text-white px-4 py-2 mt-4"
                        onClick={updateInvoice}
                      >
                        Update
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withSidebar(Invoice);
