import React from "react";

const Dropdownmenu = ({
  label = "Role",
  id,
  name,
  value,
  onChange,
  options = [],
}) => {
  return (
    <div className="w-full mb-6">
      <label
        htmlFor={id}
        className="block text-sm font-medium text-gray-700 mb-2 text-start"
      >
        {label}
      </label>
      <select
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-gray-900 bg-white"
      >
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Dropdownmenu;
