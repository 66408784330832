import React from "react";

const PrimaryInput = ({
  value,
  onChange,
  label,
  type,
  placeholder,
  disable,
  min,
  max,
  ...rest
}) => {
  return (
    <div className="w-full">
      <label className="block text-sm font-medium">{label}</label>
      <input
        className="mt-1 py-2 px-2 appearance-none block w-full rounded border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disable}
        min={min}
        max={max}
      />
    </div>
  );
};

export default PrimaryInput;
