import React from "react";
import Sidebar from "../Nav/Sidebar";
import PostCareer from "./PostCareer";
import withSidebar from "../../utils/wrapper";

const Career = () => {
  return (
    <div className="container-fluid page-body-wrapper">
      <div className="row row-offcanvas row-offcanvas-right">
        <PostCareer />
      </div>
    </div>
  );
};

export default withSidebar(Career);
