import React from "react";
import Sidebar2 from "../components/Nav/Sidebar2";
import NavBar from "../components/Nav/NavBar";

const withSidebar = (WrappedComponent) => {
  return (props) => (
    <div className="flex">
      <div className="w-[17%] bg-gray-50 border-r shadow-md">
        <Sidebar2 />
      </div>
      <div className="w-[83%] relative">
        <div className="fixed  w-[83%] z-50">
          <NavBar />
        </div>
        <div className="mt-16">
          <WrappedComponent {...props} />
        </div>
      </div>
    </div>
  );
};

export default withSidebar;
