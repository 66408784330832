import React, { useEffect, useState } from "react";
import { apiPost } from "../../../utils/apis/index";
import { Link } from "react-router-dom";
import withSidebar from "../../../utils/wrapper";
import PrimaryInput from "../../primary/PrimaryInput";
import Dropdownmenu from "../../primary/Dropdownmenu";
import { toast } from "react-toastify";

const AddCustomOrder = () => {
  const [inputs, setInputs] = useState({
    name: "",
    email: "",
    phone: "",
    companyName: "",
    companyAddress: "",
    cityName: "",
    productName: "",
    productType: "",
    thickness: "",
    productQuantity: "",
    destinationAddress: "",
    type: "",
    message: "",
  });

  const handleChange = (e) => {
    setInputs((pervState) => ({
      ...pervState,
      [e.target.name]: e.target.value,
    }));
  };

  const sendRequest = async () => {
    const res = await apiPost("customOrder/addOrder", {
      name: inputs.name,
      email: inputs.email,
      phone: inputs.phone,
      companyName: inputs.companyName,
      companyAddress: inputs.companyAddress,
      cityName: inputs.cityName,
      productName: inputs.productName,
      productType: inputs.productType,
      thickness: inputs.thickness,
      productQuantity: inputs.productQuantity,
      destinationAddress: inputs.destinationAddress,
      type: inputs.type,
    }).catch((err) => console.log(err));

    const data = await res.data;
    return data;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendRequest()
      .then((data) => toast.success("Order added successfully"))
      .then(() => {
        setInputs({
          name: "",
          email: "",
          phone: "",
          companyName: "",
          companyAddress: "",
          cityName: "",
          productName: "",
          productType: "",
          thickness: "",
          productQuantity: "",
          destinationAddress: "",
          type: "",
          message: "",
        });
      });
  };

  return (
    <div className="container-fluid px-1 py-5 mx-auto w-full">
      <div className="flex justify-end mr-4 mb-5">
        <Link to={"/all-custom-order"}>
          <button className="btn bg-blue-500 text-white">
            All custom orders
          </button>
        </Link>
      </div>
      <div className="row d-flex justify-content-center">
        <div className="col-xl-7 col-lg-8 col-md-9 col-11 text-center">
          <div className=" bg-blue-50 rounded-lg py-4">
            <h5 className="text-center mb-4 mt-4">CUSTOMER DETAILS</h5>
            <form className="card-body">
              <div className="row justify-content-between text-left">
                <div className="form-group col-sm-6 flex-column d-flex">
                  <PrimaryInput
                    label="Name"
                    name="name"
                    value={inputs.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group col-sm-6 flex-column d-flex">
                  <PrimaryInput
                    label="Email"
                    name="email"
                    value={inputs.email}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="row justify-content-between text-left">
                <div className="form-group col-sm-6 flex-column d-flex">
                  <PrimaryInput
                    label="Phone"
                    name="phone"
                    value={inputs.phone}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group col-sm-6 flex-column d-flex">
                  <PrimaryInput
                    label="Company Name"
                    name="companyName"
                    value={inputs.companyName}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              <div className="row justify-content-between text-left">
                <div className="form-group col-sm-12 flex-column d-flex">
                  <PrimaryInput
                    label="Company Address"
                    name="companyAddress"
                    value={inputs.companyAddress}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              <div className="row justify-content-between text-left">
                <div className="form-group col-sm-12 flex-column d-flex">
                  <PrimaryInput
                    label="City Name"
                    name="cityName"
                    value={inputs.cityName}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>

              <div className="row justify-content-between text-left">
                <div className="form-group col-sm-6 flex-column d-flex">
                  <PrimaryInput
                    label="Product Name"
                    name="productName"
                    value={inputs.productName}
                    onChange={handleChange}
                    required
                  />
                </div>

                {
                  <div className="form-group col-sm-6 flex-column d-flex">
                    <PrimaryInput
                      label="Product Type"
                      name="productType"
                      value={inputs.productType}
                      onChange={handleChange}
                      required
                    />
                  </div>
                }
              </div>
              <div className="row justify-content-between text-left">
                <div className="form-group col-sm-6 flex-column d-flex">
                  <PrimaryInput
                    label="Thickness"
                    name="thickness"
                    value={inputs.thickness}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="form-group col-sm-6 flex-column d-flex">
                  <PrimaryInput
                    label="Product Quantity"
                    name="productQuantity"
                    value={inputs.productQuantity}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="row justify-content-between text-left">
                <div className="form-group col-sm-12 flex-column d-flex">
                  <PrimaryInput
                    label="Destination Address"
                    name="destinationAddress"
                    value={inputs.destinationAddress}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="">
                <Dropdownmenu
                  label="Product category"
                  name="type"
                  value={inputs.type}
                  onChange={handleChange}
                  options={["Scrap", "Pipe"]}
                />
              </div>

              <div className="row justify-content-end">
                <div className="form-group col-sm-6"></div>
              </div>
              <div>
                <button className="btn btn-primary" onClick={handleSubmit}>
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withSidebar(AddCustomOrder);
