import React from "react";
import { useState } from "react";
import { apiGet, apiUpdate } from "../../../utils/apis";
import Texteditor from "../../texteditor/Texteditor";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import withSidebar from "../../../utils/wrapper";
import { toast } from "react-toastify";

const NewsView = () => {
  const [inputs, setinputs] = useState({
    title: "",
    image: "",
    category: "",
    author: "",
  });
  const navigate = useNavigate();

  const [news, setNews] = useState();

  const [description, setDescription] = useState("");
  const formData = new FormData();
  const { id } = useParams();

  const handleNewsData = async () => {
    const res = await apiGet(`news/${id}`);
    const data = await res.data;
    return data;
  };

  useEffect(() => {
    handleNewsData().then((items) => {
      setNews(items.item);
      setinputs({
        title: items.item.title,
        image: items.item.image,
        category: items.item.category,
        author: items.item.author,
      });
      setDescription(items.item.description);
    });
  }, []);

  const handleChange = (e) => {
    setinputs((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const handlePhoto = (e) => {
    setinputs({
      ...inputs,
      image: e.target.files[0],
    });
  };

  const sendRequest = async () => {
    formData.append("title", inputs.title);
    formData.append("description", description);
    formData.append("category", inputs.category);
    formData.append("author", inputs.author);
    formData.append("image", inputs.image);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    sendRequest()
      .then((data) => {
        toast.success("News updated successfully");
      })
      .then(() => navigate("/all-news"));
  };

  const handleCancle = (e) => {
    e.preventDefault();
  };

  return (
    <div className="container-fluid px-1 py-5 mx-auto mh-100">
      <div className="row d-flex justify-content-center mh-100">
        <div className="col-xl-12 col-lg-8 col-md-9 col-11 mb-4">
          {inputs && (
            <form
              onSubmit={handleSubmit}
              className="forms-sample"
              encType="multipart/form-data"
              method="post"
            >
              <div className="form-group">
                <label htmlFor="exampleInputName1">News Title</label>
                <input
                  type="text"
                  name="title"
                  value={inputs.title}
                  onChange={handleChange}
                  className="form-control"
                  id="exampleInputName1"
                  placeholder="Title"
                />
              </div>
              <div className="col-12 col-md-6 d-flex">
                <div className="form-group col-6 col-md-6">
                  <label htmlFor="exampleInputName1">Category</label>
                  <input
                    type="text"
                    name="category"
                    value={inputs.category}
                    onChange={handleChange}
                    className="form-control"
                    id="exampleInputName1"
                    placeholder="Add Category"
                  />
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="exampleInputName1">Author</label>
                  <input
                    type="text"
                    name="author"
                    value={inputs.author}
                    onChange={handleChange}
                    className="form-control"
                    id="exampleInputName1"
                    placeholder="Add Author name..."
                  />
                </div>
                <div className="form-group col-6">
                  <label> Upload news cover photo</label>
                  <div className="input-group col-xs-12">
                    <input
                      type="file"
                      accept=".png, .jpg, .jpeg"
                      // value={inputs.image}
                      name="image"
                      onChange={handlePhoto}
                      className="form-control file-upload-info"
                      //   disabled
                      placeholder="Upload Image"
                    />
                  </div>
                </div>
              </div>

              <div
                className=" "
                style={{
                  height: "500px",
                  width: "100%",
                  marginTop: "40px",
                }}
              >
                <Texteditor text={description} setText={setDescription} />
              </div>

              <input
                type="submit"
                class="btn btn-success mr-2"
                value="submit"
              />

              <button className="btn btn-light" onClick={handleCancle}>
                Cancel
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default withSidebar(NewsView);
