import React, { useState } from "react";
import PrimaryInput from "../../fields/primaryInput";
import { convertDateFormat } from "../../../utils/dates";

const customerelector = ({
  customer,
  selectedCustomer,
  setSelectedCustomer,
  basicDetails,
  handleCustomerChange,
  handleBasicDetails,
}) => {
  return (
    <div className="mb-4">
      <div className="flex items-center gap-2 ">
        <div className="lg:w-1/2 border shadow-md py-4 px-4">
          <p className="text-xl font-medium">Customer Details</p>
          <div className="mt-3 flex gap-2">
            <PrimaryInput
              label={"Name"}
              type={"text"}
              placeholder={"Select or add customer"}
              onChange={(e) => {
                handleCustomerChange("name", e.target.value);
              }}
              value={customer.name}
            />
            <PrimaryInput
              label={"Company Name"}
              type={"text"}
              placeholder={"Copmany name"}
              onChange={(e) => {
                handleCustomerChange("companyName", e.target.value);
              }}
              value={customer.companyName}
            />
          </div>
          <div className="mt-3 flex gap-4">
            <PrimaryInput
              label={"Email"}
              type={"email"}
              placeholder={"Email@yahoo.com"}
              onChange={(e) => {
                handleCustomerChange("email", e.target.value);
              }}
              value={customer.email}
            />
            <PrimaryInput
              label={"Phone"}
              type={"tel"}
              placeholder={"phone number"}
              onChange={(e) => {
                handleCustomerChange("phone", e.target.value);
              }}
              value={customer.phone}
            />
          </div>
          <div className="mt-2">
            <PrimaryInput
              label={"Address"}
              type={"text"}
              placeholder={"Add address"}
              onChange={(e) => {
                handleCustomerChange("address", e.target.value);
              }}
              value={customer.address}
            />
          </div>
        </div>
        <div className="lg:w-1/2 border shadow-md py-4 px-4 h-full">
          <p className="text-xl font-medium mb-4">Basic Details</p>
          <div className="flex gap-4">
            <PrimaryInput
              label={"Date"}
              value={convertDateFormat(basicDetails?.date)}
              type={"date"}
              placeholder={"Date"}
              onChange={(e) => {
                handleBasicDetails("date", e.target.value);
              }}
            />
            <PrimaryInput
              label={"Due date"}
              value={convertDateFormat(basicDetails?.dueDate)}
              type={"date"}
              placeholder={"Due date"}
              onChange={(e) => {
                handleBasicDetails("dueDate", e.target.value);
              }}
            />
          </div>
          <div className="mt-2">
            <PrimaryInput
              label={"Truck Driver Name"}
              value={basicDetails?.truckDriverName}
              type={"text"}
              placeholder={"Add truck driver name"}
              onChange={(e) => {
                handleBasicDetails("truckDriverName", e.target.value);
              }}
            />
          </div>
          <div className="flex gap-4 mt-2">
            <PrimaryInput
              label={"Truck Ref"}
              value={basicDetails?.truckRef}
              type={"text"}
              placeholder={"Add truck no."}
              onChange={(e) => {
                handleBasicDetails("truckRef", e.target.value);
              }}
            />
            <PrimaryInput
              label={"Truck Driver Phone"}
              value={basicDetails?.truckDriverPhone}
              type={"number"}
              placeholder={"Add phone number"}
              onChange={(e) => {
                handleBasicDetails("truckDriverPhone", e.target.value);
              }}
            />
          </div>
        </div>
      </div>

      {customer?.length > 0 && (
        <select
          className="mt-2 block w-full rounded-md border-gray-300 py-2 px-3 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          onChange={(e) => {
            setSelectedCustomer(e.target.value);
          }}
          value={selectedCustomer}
        >
          <option value="">Select a customer</option>
          {customer.map((customer, index) => (
            <option key={index} value={customer}>
              {customer}
            </option>
          ))}
        </select>
      )}
    </div>
  );
};

export default customerelector;
