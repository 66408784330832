import React from "react";

const TableComponent = ({
  name,
  email,
  phone,
  companyName,
  date,
  message,
  isSet,
}) => {
  return (
    <form className="card-body">
      <div className="row justify-content-between text-left">
        <div className="form-group col-sm-6 flex-column d-flex">
          {" "}
          <label className="form-control-label px-3">
            Name<span className="text-danger"> *</span>
          </label>{" "}
          <input
            type="text"
            value={name}
            id="fname"
            name="fname"
            disabled
            onblur="validate(1)"
          />
        </div>
        <div className="form-group col-sm-6 flex-column d-flex">
          {" "}
          <label className="form-control-label px-3">
            Email<span className="text-danger"> *</span>
          </label>
          <input
            type="text"
            id="lname"
            value={email}
            name="lname"
            disabled
            onblur="validate(2)"
          />
        </div>
      </div>
      <div className="row justify-content-between text-left">
        <div className="form-group col-sm-6 flex-column d-flex">
          {" "}
          <label className="form-control-label px-3">
            Phone
            <span className="text-danger"> *</span>
          </label>{" "}
          <input
            type="text"
            id="phone"
            value={phone}
            name="phone"
            placeholder
            disabled
            onblur="validate(3)"
          />{" "}
        </div>
        <div className="form-group col-sm-6 flex-column d-flex">
          {" "}
          <label className="form-control-label px-3">
            Company Name
            <span className="text-danger"> *</span>
          </label>{" "}
          <input
            type="text"
            id="mob"
            value={companyName}
            name="mob"
            disabled
            onblur="validate(4)"
          />{" "}
        </div>
      </div>

      {isSet && (
        <div className="row justify-content-between text-left">
          <div className="form-group col-sm-12 flex-column d-flex">
            {" "}
            <label className="form-control-label px-3">
              Date
              <span className="text-danger"> *</span>
            </label>{" "}
            <input
              type="text"
              id="job"
              value={date}
              name="job"
              disabled
              onblur="validate(5)"
            />{" "}
          </div>
        </div>
      )}
      <div className="row justify-content-between text-left">
        <div className="form-group col-12 flex-column d-flex">
          {" "}
          <label className="form-control-label px-3">
            Message
            <span className="text-danger"> *</span>
          </label>{" "}
          <textarea
            type="text"
            id="ans"
            name="ans"
            value={message}
            disabled
            onblur="validate(6)"
          />{" "}
        </div>
      </div>
      <div className="row justify-content-end">
        <div className="form-group col-sm-6">
          {" "}
          {/* <button
          type="submit"
          className="btn-block btn-primary"
        >
          Request a demo
        </button>{" "} */}
        </div>
      </div>
    </form>
  );
};

export default TableComponent;
