import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { apiDelete, apiGet } from "../../../utils/apis";
import withSidebar from "../../../utils/wrapper";

const Qoutes = () => {
  const [items, setItems] = useState("");

  const getTestimonialData = async () => {
    try {
      const res = await apiGet("qoute");
      const data = await res.data;
      return data;
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!items) {
      getTestimonialData().then((data) => setItems(data.qoute));
    }
  });

  const deleteItem = (id) => {
    const url = `qoute/${id}`;
    apiDelete(url)
      .then(() => alert("are you sure?"))
      .then(() => window.location.reload(true))
      .catch((err) => console.log(err));
  };
  return (
    <div className=" col-md-12">
      <div className="card-body col-md-12 ">
        <h4 className="card-title">Qoutes List</h4>
        <div className="table-responsive"></div>
        <table className="table table-striped">
          <tbody>
            <tr>
              <th>Name</th>
              <th>Phone</th>
              <th className="col-md-5">Assit Quaries</th>
              <th>Action</th>
            </tr>
            {items &&
              items.map((items, idx) => {
                return (
                  <tr key={idx}>
                    <td>{items.name}</td>
                    <td>{items.phone}</td>
                    <td>{items.assist}</td>

                    <td className="d-flex flex-row align-items-center">
                      <i
                        className="fa fa-edit"
                        style={{
                          fontSize: 26,
                          marginRight: 10,
                          cursor: "pointer",
                          marginTop: 2,
                          color: "#0000ff",
                        }}
                      ></i>
                      <i
                        className="fa fa-trash-o"
                        style={{
                          fontSize: 26,
                          marginLeft: 10,
                          cursor: "pointer",
                          color: "#ff0000",
                        }}
                        onClick={() => deleteItem(items._id)}
                      ></i>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default withSidebar(Qoutes);
