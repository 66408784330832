import React, { useState } from "react";

import { apiPost } from "../../utils/apis/index";
import { Link } from "react-router-dom";
import withSidebar from "../../utils/wrapper";
import PrimaryInput from "../primary/PrimaryInput";
import Dropdownmenu from "../primary/Dropdownmenu";
import { toast } from "react-toastify";

const NewUser = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [inputs, setInputs] = useState({
    name: "",
    username: "",
    phone: "",
    email: "",
    password: "",
    role: "editor",
  });

  const handleChange = (e) => {
    setInputs((pervState) => ({
      ...pervState,
      [e.target.name]: e.target.value,
    }));
  };

  const sendRequest = async () => {
    const res = await apiPost("auth/register", {
      name: inputs.name,
      username: inputs.username,
      phone: Number(inputs.phone),
      email: inputs.email,
      password: inputs.password,
      role: inputs.role,
    }).catch((err) => console.log(err));

    const data = await res.data;

    return data;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sendRequest()
      .then((data) => toast.success("User added successfully"))
      .then(() => {
        setInputs({
          username: "",
          name: "",
          email: "",
          password: "",
          phone: "",
          role: "",
        });
      });
  };

  return (
    <div className="container-fluid px-1 py-5 ">
      <div className=" flex justify-end mr-6">
        <Link to={"/user-list"}>
          <button className="btn bg-blue-500 text-white">All users</button>
        </Link>
      </div>
      <div className="row d-flex justify-content-center">
        <div className="">
          <div className="">
            <h3>ADD USER</h3>
            <p className="mt-2 text-sm font-medium">
              This is the user creation page. Here you can add a new user to the
              system. <br />
              <span className="text-[10px] text-blue-700">
                [ Note: Please fill the form below to add a new user to the
                system.]
              </span>
            </p>
          </div>

          <div className="card">
            <h5 className="text-start mb-4 mt-4">USER DETAILS</h5>
            <form className="bg-blue-50 py-6 px-6 rounded-lg">
              <div className="flex  gap-2 justify-between">
                <PrimaryInput
                  label="Name"
                  name="name"
                  type="text"
                  value={inputs?.name}
                  onChange={handleChange}
                  required
                />
                <PrimaryInput
                  label="Username"
                  name="username"
                  type="text"
                  value={inputs?.username}
                  onChange={handleChange}
                />
              </div>
              <div className="flex gap-2 justify-between">
                <PrimaryInput
                  label="Email"
                  name="email"
                  type="email"
                  value={inputs?.email}
                  onChange={handleChange}
                  required
                />
                <PrimaryInput
                  label="Phone"
                  name="phone"
                  type="text"
                  value={inputs?.phone}
                  onChange={handleChange}
                />
              </div>
              <div className="flex justify-between gap-2">
                <div className="w-1/2">
                  <PrimaryInput
                    label="Password"
                    name="password"
                    type={`${showPassword ? "text" : "password"}`}
                    value={inputs?.password}
                    onChange={handleChange}
                    required
                  />
                  <p
                    className="text-end cursor-pointer -mt-14 mr-2 select-none"
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                  >
                    {showPassword ? "Show" : "Hide"}
                  </p>
                </div>
                <div>
                  <Dropdownmenu
                    label="Role"
                    id="role"
                    name="role"
                    value={inputs?.role}
                    onChange={handleChange}
                    options={["admin", "editor"]}
                  />
                </div>
              </div>

              <div className="row justify-content-end">
                <div className="form-group col-sm-6"></div>
              </div>
              <div>
                <button className="btn btn-primary" onClick={handleSubmit}>
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withSidebar(NewUser);
