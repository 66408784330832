import axios from "axios";
import { ApiURL } from "../../config/config";
// import { config } from "../config";

const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("authToken")}`,
  },
};

const baseUrl = ApiURL;

export const apiGet = async (url, configs, ...rest) => {
  const requestURL = `${baseUrl}/${url}`;
  const response = axios.get(requestURL, configs ? configs : config, ...rest);
  return response;
};

export const apiPost = async (url, inputs, ...rest) => {
  const requestURL = `${baseUrl}/${url}`;
  const response = axios.post(requestURL, inputs, config, ...rest);
  return response;
};

export const apiDelete = async (url, ...rest) => {
  const requestURL = `${baseUrl}/${url}`;
  const response = axios.delete(requestURL, config, ...rest);
  return response;
};

export const apiUpdate = async (url, inputs, ...rest) => {
  const requestURL = `${baseUrl}/${url}`;
  const response = axios.put(requestURL, inputs, config, ...rest);
  return response;
};

export const apiPatch = async (url, inputs, ...rest) => {
  const requestURL = `${baseUrl}/${url}`;
  const response = axios.patch(requestURL, inputs, config, ...rest);
  return response;
};
