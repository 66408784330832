import React from "react";
import Sidebar from "../../Nav/Sidebar";
import ScrapOrderTable from "./ScrapOrderTable";
import withSidebar from "../../../utils/wrapper";

const Scrap = () => {
  return (
    <div className="container-fluid page-body-wrapper">
      <div className="row row-offcanvas row-offcanvas-right">
        <ScrapOrderTable />
      </div>
    </div>
  );
};

export default withSidebar(Scrap);
