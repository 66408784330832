import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { apiDelete, apiGet } from "../../utils/apis/index";

const TestimonialTable = () => {
  const [items, setItems] = useState("");

  const getTestimonialData = async () => {
    try {
      const res = await apiGet("testimonial");
      const data = await res?.data;
      return data;
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!items) {
      getTestimonialData().then((data) => setItems(data?.items));
    }
  });

  const deleteItem = (id) => {
    const url = `testimonial/${id}`;
    apiDelete(url)
      .then(() => alert("are you sure?"))
      .then(() => window.location.reload(true))
      .catch((err) => console.log(err));
  };

  return (
    <div className="card-body">
      <h4 className="card-title">Testimonial List</h4>
      <div className="table-responsive"></div>
      <table className="table table-striped">
        <tbody>
          <tr>
            <th></th>
            <th>Name</th>
            <th>Designation</th>
            <th>Company</th>
            <th>Details</th>
            <th>Action</th>
          </tr>
          {items &&
            items?.map((items, idx) => {
              return (
                <tr key={idx}>
                  <td>
                    {" "}
                    <img
                      src={`http://localhost:5000/static/${items?.image}`}
                      alt={"profile"}
                    />
                  </td>
                  <td>{items?.name}</td>
                  <td>{items?.designation}</td>
                  <td>{items?.company}</td>
                  <td>{items?.testimonial}</td>

                  <td className="d-flex flex-row align-items-center">
                    <i
                      className="fa fa-edit"
                      style={{
                        fontSize: 26,
                        marginRight: 10,
                        cursor: "pointer",
                        marginTop: 2,
                        color: "#0000ff",
                      }}
                    ></i>
                    <i
                      className="fa fa-trash-o"
                      style={{
                        fontSize: 26,
                        marginLeft: 10,
                        cursor: "pointer",
                        color: "#ff0000",
                      }}
                      onClick={() => deleteItem(items?._id)}
                    ></i>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default TestimonialTable;
