import React from "react";
import PrimaryInput from "../../fields/primaryInput";

const ItemList = ({ items, handleItemChange, addItem, removeItem }) => {
  return (
    <div className="mb-4  px-2">
      <h2 className="text-xl font-medium mb-6">Items</h2>
      <table className="min-w-full divide-y divide-gray-200 bg-white">
        <thead>
          <tr>
            <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Item Name
            </th>
            <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Description
            </th>
            <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Quantity (pcs)
            </th>
            <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Quantity (kg)
            </th>
            <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Amount
            </th>
            <th className="px-6 py-3 bg-gray-50"></th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {items.map((item, index) => (
            <tr key={index}>
              <td className="pr-2 py-2 whitespace-nowrap">
                <PrimaryInput
                  placeholder="Item name"
                  value={item.itemName}
                  onChange={(e) =>
                    handleItemChange(index, "itemName", e.target.value)
                  }
                />
              </td>
              <td className="pr-2 py-4 whitespace-nowrap">
                <PrimaryInput
                  placeholder="Description"
                  value={item.description}
                  onChange={(e) => {
                    let value = e.target.value;
                    if (value === 0 || value === "") {
                      handleItemChange(index, "description", 0);
                    } else {
                      handleItemChange(index, "description", value);
                    }
                  }}
                />
              </td>
              <td className="pr-2 py-4 whitespace-nowrap">
                <PrimaryInput
                  placeholder="Quantity (pcs)"
                  type={"number"}
                  min={0}
                  value={item.quantityPcs}
                  onChange={(e) =>
                    handleItemChange(index, "quantityPcs", e.target.value)
                  }
                />
              </td>
              <td className="py-4 ">
                <PrimaryInput
                  placeholder="Quantity (kg)"
                  type={"number"}
                  value={item.quantityKg}
                  onChange={(e) =>
                    handleItemChange(index, "quantityKg", e.target.value)
                  }
                />
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <PrimaryInput
                  placeholder="Amount"
                  type={"number"}
                  min={0}
                  value={item.amount}
                  onChange={(e) =>
                    handleItemChange(
                      index,
                      "amount",
                      parseFloat(e.target.value)
                    )
                  }
                />
              </td>
              {index > 0 && (
                <td className="px-6 py-4 whitespace-nowrap">
                  <button
                    onClick={() => removeItem(index)}
                    className="text-red-500"
                  >
                    <i
                      className="fa fa-trash text-blue-500"
                      aria-hidden="true"
                    ></i>
                  </button>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      <button
        onClick={addItem}
        className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-md"
      >
        Add Item
      </button>
    </div>
  );
};

export default ItemList;
